.paymentCont{
    border-radius: 3px;
    border: 1px solid #DEE2E6;
    background: #FFF;
    box-shadow: 0px 8px 35px 0px rgba(73, 80, 87, 0.05);
    padding: 24px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.myFileRep{
    border-radius: 8px;
    z-index: 100000;
    background: #FFF;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    /*width: 250px;*/
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
}

.fileexeName{
    color: #555;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: normal;
}

.selectfilecont{
    display: flex;
    width: 224px;
    padding: 16px 32px 16px 30px;
    justify-content: center;
    align-items: center;
    color: #FAFCFF;
    font-family: Rubik;
    margin: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 10px;
    border-radius: 8px;
    background: #64BB68;
}

.myIn{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.myIn img{
    width: 23px;
}

.genpadd{
    padding: 15px;
}

.paymentRadio{
    display: inline-flex;
    flex-direction: row;
}

.myCreateResBtn{
    margin-top: 15px;
}

.myCreateResBtn .visa_casing{
   margin: 0;
}

.la{
    padding: 10px 10px 10px 0;
    color: #505050;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

/*input[type="radio"]  it also affects visa page{*/
/*    width: 20px;*/
/*    height: 20px;*/
/*}*/

.paymentHeader{
    color: #234;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.paymentsubtext{
    color: #565A61;
    text-align: justify;
    font-family: Rubik;
    font-size: 12px;
    margin-top: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.paymentPricecont {
    display: inline-flex;
    flex-direction: row;
    color: #565A61;
    font-family: Rubik;
    font-size: 12px;
    margin-top: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    align-items: center;
    justify-content: space-around;
}

.BankCont{
    margin: 24px;
    display: flex;
    border-radius: 5px;
    border: 1px solid #DEE2E6;
    background: #FFF;
    box-shadow: 0px 8px 35px 0px rgba(73, 80, 87, 0.05);
    padding: 20px;
    flex-direction: column;
    align-items: stretch;

}

.bnkName{
    color: #000;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 16px;
    line-height: normal;
}

.accDetailsn{
    color: #000;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}

.accDetails{
    margin-top: 16px;
}

.accDetailsn {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 33px;
    cursor: pointer;
}

.accname{
    color: #000;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.myCreateRes{
    display: flex;
    width: 310px;
    height: 40px;
    padding: 16px 32px 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
    margin-bottom: 60px;
    flex-shrink: 0;
    border-radius: 8px;
    background:  #054DAB;
    color: #FFFFFF;
}

.payment_conf{
    padding: 20px;
    border-radius: 3px;
    border: 1px solid #DEE2E6;
    background: #FFF;
    box-shadow: 0px 8px 35px 0px rgba(73, 80, 87, 0.05);
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.filee{
    border-radius: 13.246px;
    border: 2.285px dashed #808388;
    background: #EAF2FE;
    padding: 20px;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.filee:hover{
    border: 2.285px dashed #054DAB;
}

.filee input{
    width: 100%;
}

.payHeader{
    color: #234;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
}

.paySubText{
    color:  #565A61;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
    margin-bottom: 31px;
}

.BookingAmtconf{
    color:  #565A61;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Amountpayconf{
    color: #565A61;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.myCreateConf{
    display: flex;
    width: 250px;
    padding: 16px 32px 16px 30px;
    justify-content: center;
    align-items: center;
    margin-bottom: 41px;
    margin-top: 61px;
    gap: 10px;
    border-radius: 8px;
    color: #FFFFFF;
    background:  #054DAB;
}

.fileText{
    color:  #555;
    text-align: center;
    font-family: Poppins;
    font-size: 13.246px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.formPay{
    background: #EEF5FE;
    margin-top: 61px;
    padding: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.formpayHeader{
    color: #000;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.formpaySubText{
    color: #000;
    text-align: center;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12.5px;
}

.codeReq{
    color: #054DAB;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 25px;
    margin-top: 25px;
}

.confForm{
    border-radius: 2px;
    border: 1px solid #CFCFCF;
    background:  #FFF;
    color: black;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.confForm{
    border: 2px solid  rgba(8, 137, 242, 0.80);
}

.conferr{
    /*border-radius: 8px;*/
    border: 2px solid  #DC3545;
}

.confSuc{
    /*border-radius: 8px;*/
    border: 2px solid #00ff2b;
}

.errc{
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.popupcont{
    display: inline-flex;
    padding: 5px 38px 30px 39px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
    text-align: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    width: 400px;
}
.inpcomp{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
}

.headerpop{
    color: #000;
    text-align: center;
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 16px;
    margin-bottom: 16px;
}

.popupimg{
    margin-top: 20px;
}

.subtextpop{
    color: #000;
    text-align: center;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.popupmain {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    right: 0;
    bottom: 0;
    background: rgb(2 11 79 / 26%);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(2px);
    justify-content: center;
    justify-items: center;
    flex-wrap: nowrap;
    align-items: center;
}

@media (min-width: 760px) {
    .makeInvi{
        display: none;
    }
    .paymentHeader{
        color: #234;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.087px;
    }
    .paymentCont, .payment_conf, .genpadd{
        width: 407px;
    }

    .BankCont{
        width: 308px;
    }

    .paymentsubtext, .paymentPricecont{
        font-size: 14px;
    }

   .myCreateResBtn .visa_casing {
        display: inline-flex;
        gap: 14px;
        flex-direction: row-reverse;
        float: right;
        margin: unset;
        margin-bottom: 90px;
        justify-content: space-between;
    }

    .ECU .visa_casing {
        display: inline-flex;
        gap: 14px;
        flex-direction: row-reverse;
        float: right;
        margin: unset;
        margin-bottom: 90px;
        justify-content: center;
    }
}


@media (min-width: 1024px) {
    .paymentCont, .payment_conf, .genpadd {
        width: 577px;
    }

   .myCreateResBtn .visa_next {
        width: 366px;
        height: 56px;
        font-size: 14px;
    }

    /*.confForm{*/
    /*    width: 200px;*/
    /*    height: 48px;*/
    /*}*/

    /*.formPay{*/
    /*    width: 300px;*/
    /*    height: 150px;*/
    /*}*/

    /*.payment_conf{*/
    /*    width: 500px;*/
    /*    height: 597px;*/
    /*}*/
}