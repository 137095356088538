/*.AboutUsMain{*/
/*    font-size: clamp(10px, 1.2vw, 16px);*/
/*}*/

.hero-container {
    background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001741/JaromTravels/b61imrqo59sbbtlzb5ay.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;
    padding: 32% 22px 32% 22px;
    margin-top: 48px;
}

.hero-container1{
    background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716000419/JaromTravels/lxaafctqfe6almutvolt.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;
    padding: 32% 22px 32% 22px;
    margin-top: 48px;
}


.hero-img{
width: 100%;
user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
user-drag: none;
-moz-user-drag: none;
-webkit-user-drag: none;
-ms-user-drag: none;
z-index: -100;
margin-top: 48px;
}


.hero-text{
    font-family: 'Rubik', serif;
    color: white;
}

.hero-text h1{
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    margin: 0 auto 16px auto;
    letter-spacing: -0.75px;
}

.hero-text p{
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin: auto;
}

/*AboutMain*/
.aboutMain{
    margin: 18px 21px 18px 21px;
}

.aboutMain{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: clamp(12px, 1.5vw, 20px);
    line-height: clamp(21px, 1.5vw, 36px);
}

.aboutMain h2{
    font-weight: 700;
    font-size: clamp(24px, 1.5vw, 48px);
    line-height: 28px;
    text-align: left;
    letter-spacing: -0.75px;
    color: #032756;
    margin: 24px 0 18px 0;
}

/*Contact Us*/
.contactUs{
    font-size: clamp(12px, 1.5vw, 20px);
    background: #E0F1E1;
    padding: 18px 21px 18px 21px;
    text-align:  center;
    margin-bottom: 64px;
    margin-top: 48px;
}

.contactUs h1{
    font-family: 'Rubik';
    font-size: clamp(24px, 1.5vw, 50px);
    padding: 30px 20px 14px 20px;
    font-weight: 600;
}

.contactUs p{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /*width: 320px;*/
    margin: auto;
    padding-bottom: 18px;
}

/*ContactUs_button*/
.AboutUsButton{
    background: #054DAB;
    color: white;
    border: none;
    /*margin-top: 21px;*/
    width: 180px;
    height: 30px;
    font-size: 9px;
    border-radius: 5px;
    margin-bottom: 43px;
}

/*Media Queries*/

@media (min-width: 300px){
    .hero-text h1{
        height: 28px;
        font-size: 24px;
        /*margin-bottom: 20px;*/
    }

    .hero-text p{
        width: 215px;
        height: 34px;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.125px;
    }

}

@media (min-width: 500px){
    .hero-container{
        padding: 35% 22px 32% 22px
    }
}

@media (min-width: 560px){
    /*.hero-text{*/
    /*    width: 111%;*/
    /*    height: 78px;*/
    /*    padding: 126px 80px 144px 80px;*/
    /*}*/

    .hero-text h1{
        font-size: 30px;
    }

    .hero-container{

    }

    .hero-container1{
        height: 467px;
    }

    .hero-text p{
        width: 293px;
        height: 34px;
        font-size: 15px;
        line-height: 17px;
        letter-spacing: 0.125px;
        margin: auto;
    }

    /*AboutMain*/



    /*Contact Us*/


    .contactUs p{
        /*width: 400px;*/
        margin: auto;
    }




}

@media (min-width: 760px){

    .hero-container {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001771/JaromTravels/ozxmaaxf6ojqanxa2itk.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 21% 22px 21% 22px;

    }

    .hero-container1 {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716000459/JaromTravels/n4pvkdbw50cejhdtz7hj.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 21% 22px 21% 22px;
    }

    .hero-text{

    }

    .hero-text h1{
        font-size: 37px;
        margin-bottom: 23px;
    }

    .hero-text p{
        font-size:21px ;
        width: 560px;
        margin: auto;
    }

    /*.decoy{*/
    /*    position: relative;*/
    /*    top: 136px;*/
    /*}*/

    .aboutMain{
        margin: 18px 68px 18px 68px;
    }

    .contactUs{
        padding: 18px 68px 18px 68px;
    }

    .AboutUsButton{
        width: 180px;
        height: 30px;
        font-size: 9px;
        margin-top: 21px;
        margin-bottom: 21px;
    }

    .contactUs p {
        line-height: 20px;
    }
}

@media (min-width: 854px){
    .hero-container {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001771/JaromTravels/ozxmaaxf6ojqanxa2itk.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 20% 22px 22% 22px;

    }

    .hero-container1 {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716000459/JaromTravels/n4pvkdbw50cejhdtz7hj.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 20% 22px 22% 22px;
    }
}

@media (min-width: 1024px) {
    .hero-text h1 {
        font-size: 40px;
    }

    .aboutMain p {
        line-height: 26px;
    }

    .contactUs h1 {
        width: 750px;
        margin: auto;
        padding-bottom: 20px;
    }

    .contactUs p {
        padding: 0px 57px 0 73px;
        margin-bottom: 27px;
    }

    .hero-text{

    }


    .hero-container {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001771/JaromTravels/ozxmaaxf6ojqanxa2itk.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 23% 22px 23% 22px;

    }

    .hero-container1 {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716000459/JaromTravels/n4pvkdbw50cejhdtz7hj.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 23% 22px 23% 22px;
    }

}

@media (min-width: 1151px){
    .hero-container {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001771/JaromTravels/ozxmaaxf6ojqanxa2itk.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 20% 22px 20% 22px;
    }

    .hero-container1 {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716000459/JaromTravels/n4pvkdbw50cejhdtz7hj.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 20% 22px 20% 22px;
    }
}

@media (min-width: 1221px){
    .hero-container {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001771/JaromTravels/ozxmaaxf6ojqanxa2itk.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 25% 22px 34% 22px;
    }

    .hero-container1 {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716000459/JaromTravels/n4pvkdbw50cejhdtz7hj.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 25% 22px 34% 22px;
    }
}


@media (min-width: 1280px){
    .contactUs h1{
        width: 900px;
        margin: auto;
        padding-bottom: 20px;
    }

    .contactUs p{
        padding: 0px 100px 0 100px;
        margin-bottom: 27px;
    }

    .contactUs p {
        padding: 0px 209px 0 209px;
        margin-bottom: 27px;
    }

    .contactUs h1 {
        font-family: 'Rubik';
        font-size: clamp(38px, 1.2vw, 50px);
    }

    .contactUs p {
        line-height: 36px;
    }

    .hero-container {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001771/JaromTravels/ozxmaaxf6ojqanxa2itk.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 26% 22px 26% 22px;
    }


    .hero-container1 {
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716000459/JaromTravels/n4pvkdbw50cejhdtz7hj.svg);
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        padding: 26% 22px 26% 22px;
    }

}

@media (min-width: 1440px){
    .AboutUsButton{
        width: 311px;
        height: 52px;
        font-size: 16px;
        margin-top: 38px;
        margin-bottom: 75px;
    }

    .hero-text h1{
        font-size: 64px;
        margin-bottom: 40px;
    }

    .hero-text p {
        font-size: 35px;
        width: 100%;
    }

    .hero-text {

    }

    .aboutMain {
        margin: 48px 120px 72px 120px;
    }

}

@media (min-width: 1441px){
    .AboutUsMain{
        margin: auto;
        max-width: 1440px;
    }

}