:root{
    --black: #000204;
    --primary: #032756;
}

.imgfldiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}



@media (min-width: 300px) {
    .bodyContainer{
        display: flex;
        margin-top: 50px;
        justify-items: center;
        flex-direction: column;
        align-items: center;
    }


    .propsContainer {
        position: relative;
        text-align: center;
        background: var(--primary);
        color: #FFFFFF;
        width: 243px;
        padding: 36px 18.75px 73px 19.5px;
        margin-bottom: 32px;
        max-height: 340px;
    }

    .headerText{
        color: var(--black);
        text-align: center;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.15px;
        margin: 24px 0 32px 0;
    }

    .propsImg{
        margin-bottom: 24px;
        width: 52px;
    }

    .propsHeaderText{
        color: #FFF;
        text-align: center;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 6px;
    }

    .propsMainText{
        margin-bottom: 32px;
        color:  #C0C8D4;
        text-align: center;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .propsBtn{
        border-radius: 8px;
        background:#054DAB;
        color: #F0F6FE;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        height: 40px;
        padding: 6px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin-right: 0;
    }
}

.withform{
    position: relative;
}

.form{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 84%;
    background: #82A6D5;
}

.mginput{
    display: flex;
    gap: 7px;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 3.75px;
    border: 1.5px solid  #FAFCFF;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px;
}



.mginput input::placeholder{
    color: #FFFFFF;
}

.mginput input{
    background: transparent;
    border:none;
    width: 100%;
    color: #FAFCFF;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mginput img{
    width: 18px;
    height: 18px;
}

.presh{
    width: 34px;
    height: 34px;
    margin-bottom: 8px;
}

.adjForm{
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adjForm button{
    display: flex;
    width: 186px;
    height: 45px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    margin-bottom: 10px;
    border-radius: 3.75px;
    background: #054DAB;
    border: none;
    color: #FFFFFF;
}

@media (min-width: 760px) {
    .headerText{
        color: var(--black);
        text-align: center;
        font-family: Rubik;
        font-size: 32.48px;
        font-style: normal;
        font-weight: 700;
        line-height: 141.5%;
        letter-spacing: -0.29px;
    }

    .manageBookingWrapper{
        display: inline-flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 50px;
    }

    .propsContainer {
        margin-bottom: 0;
        height: 327px;
        max-height: 346px;
    }

    .propsHeaderText{
        font-size: 14px;
    }

    .propsMainText {
        font-size: 10px;
        margin-bottom: 64px;
    }

    .propsImg{
        width: 62px;
    }

    .adjForm button {
        width: 202px;
    }
}

@media (min-width: 1024px) {
    .manageBookingWrapper{
        gap: 20px;
        margin-bottom: 120px;
    }

    .propsHeaderText{
        font-size: 24px;
    }



    .propsMainText {
        font-size: 16px;
        margin-bottom: 101px;
    }

    .propsImg {
        margin-bottom: 32px;
    }

    .propsContainer {
        background: #032756;
        background: var(--primary);
        color: #fff;
        max-height: 424px;
        padding: 36px 18.75px 73px 19.5px;
        position: relative;
        text-align: center;
        width: 301px;
        height: 424px;
    }

    .mainForm{
        width: 83%;
    }

    .mginput {
        display: flex;
        gap: 7px;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 31px;
        border-radius: 3.75px;
        border: 1.5px solid #FAFCFF;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px;
        height: 45px;
    }

    .propsBtn{
        display: flex;
        width: 253px;
        height: 48px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: 20px;
    }

    .headerText{
        color: var(--black);
        text-align: center;
        font-family: Rubik;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.15px;
        margin: 73px 0 40px 0;
    }

    .imgfldiv {
        display: flex;
        flex-direction: row;
        padding-right: 10px;
        justify-content: flex-end;
    }

    .presh {
        width: 46px;
        height: 64px;
        margin-bottom: 8px;
    }

    .adjForm button{
        width: 251px;
    }
}