.SupportMainBody{
    margin: 24px auto;
    text-align: center;
    width: 238px;
    border: 0.5px solid #2C313A;
    border-radius: 4px;
}

.Icon{
    width: 13px;
    height: 13px;
}

.phone, .email, .location{
    margin: 24px 56px;
    border: 0.5px solid #565A61;
    border-radius: 4px;
    padding: 0 10px;
}
.ContactUsText{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 24px;
}

.ReachOutContainer p{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    color: #2C313A;
}

.SupportMainContainer{
    display: inline-flex;
    flex-direction: column;
    text-align: center;
}



.OrText{
    margin-bottom: 20px;
    margin-top: 18px;
    text-align: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.25px;
    color: #080D18;
}

.formData{
    margin: 24px auto;
    text-align: center;

}

.textInputField{
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
}

.messageSupp{
    font-style: normal;
    font-weight: 500;
    font-size: clamp(8px, 2vw, 12px);
    line-height: 14px;
    color:#0a58ca ;
    text-align: left;
    /*padding-left: 14%;*/
    margin-top: 5px;
}

.cruise{
    width: 323px;
    height: 42px;
    background-color: unset;
    border: 0.75px solid #020812;
    border-radius: 6px;
}

.cruise:focus, .dodo:focus{
    border: #0a58ca solid 2px;
}

.cruise, .dodo{
    text-indent: 36px;
    font-size: 12px;
    font-weight: 500;
}

.dodo:focus-visible{
    outline: none;
}

.SupportButton {
    font-size: 13px;
    width: 320px;
    height: 40px;
    margin-bottom: 193px;
    margin-top: 42px;
    background: #054DAB;
    color: white;
    border: none;
    border-radius: 5px;
}

.dodo{
    background-color: unset;
    width: 320px;
    height: 150px;
    border: 0.75px solid #020812;
    border-radius: 6px;
    padding-bottom: 20px;
    margin-top: 36px;
    margin-buttom: 21px;
    text-indent: 9px;
    padding-top: 14px;
}

.sendUsMessageText{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #080D18;
    margin-bottom: 8px;
}
.iconInput{
    position: relative;
}

.iconInput .icon{
    position: absolute;
    top: 7px;
    left: 11px;
}


.iconInput img{
    filter: brightness(0) invert(0);
    width: 18px;
    height: 18px;
}



@media (min-width: 760px){
    /*.SupportMainContainer{*/
    /*    display: inline-flex;*/
    /*    flex-direction: row;*/
    /*    text-align: center;*/
    /*}*/

    .SupportMainBody{
        width: unset;
        border: none;
        border-radius: unset;
    }
    .ReachOutContainer{
        display: inline-flex;
        flex-direction: row;
        gap: 69px;
    }

    .ReachOutContainer p {
        font-size: 9px;
        width: 92%;
        margin: 0 auto;
    }

    .phone, .email, .location{
        width: 145px;
        height: 70px;
        margin: unset;
        padding: 0 3px;
    }

    .sendUsMessageText{
        margin-bottom: 30px;
    }

    .ContactUsText{
        margin-top: 13px;
        font-size: 18px;
        margin-bottom: 35px;
    }
    .emailIcon, .phoneIcon, .locationIcon{
        margin-top: 10px;
        margin-bottom: 10px;
    }


    .OrText{
        font-size: 20px;
    }

    .cruise{
        width: 419px;
        height: 32px;
    }

    .dodo{
        width: 419px;
        height: 116px;
    }

    .SupportButton{
        font-size: 13px;
        height: 35px;
        margin-bottom: 193px;
        margin-top: 42px;
        width: 419px;
        background: #054DAB;
        color: white;
        border: none;
        border-radius: 5px;
    }

    .iconInput .icon{
        top: 1px;
    }

}

@media (min-width: 1024px){}

@media (min-width: 1280px){}

@media (min-width: 1440px){
    .phone, .email, .location {
        width: 250px;
        height: 121px;
        margin: unset;
        padding: 5px;
        border-radius: 8px;
    }

    .ReachOutContainer p{
        font-size: 16px;
        line-height: 18px;
        width: 84%;
        margin: 0 auto;
    }

    .ContactUsText{
        font-size: 24px;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .cruise{
        width: 722px;
        height: 56px;
    }

    .iconInput img {
        filter: brightness(0) invert(0);
        width: 24px;
        height: 24px;
    }

    .iconInput .icon{
        top: 10px;
        left: 9px;
    }

    .cruise, .dodo{
        text-indent: 48px;
        font-size: 16px;
    }

    .dodo{
        width: 718px;
        height: 200px;
        text-indent: 13px;
    }

    .sendUsMessageText{
        font-size: 24px;
        margin-top: 52px;
    }

    .SupportButton{
        width: 718px;
        height: 60px;
        font-size: 20px;
    }

    .OrText{
        margin-top: 75px;
        font-size: 35px;
        margin-bottom: 32px;
    }

    .Icon {
        width: 24px;
        height: 24px;
    }
}

@media (min-width: 1441px){
    .supportPageBody{
        margin: auto;
        max-width: 1440px;
    }
}

