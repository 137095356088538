body {
    margin: 0;
    line-height: normal;
}

.RS:checked + .unavSeatOp {
    border: 2px solid blue; /* Change this to your desired border style */
}

:root {
    /* fonts */
    --font-nunito-sans: "Nunito Sans";

    /* font sizes */
    --font-size-sm: 14px;
    --font-size-xs: 12px;

    /* Colors */
    --true-white: #fff;
    --grey-400: #7c8db0;

    /* Gaps */
    --gap-8xs: 5px;

    /* Paddings */
    --padding-9xs: 4px;
    --padding-5xs: 8px;

    /* border radiuses */
    --br-10xs-3: 2.3px;
    --br-5xs: 8px;
}


.seatgroupCompatment{
    display: inline-flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
}

.plane-seat-selection-child,
.plane-seat-selection-item {
    position: absolute;
    top: calc(50% - 302.5px);
    left: calc(50% + 234px);
    width: 150px;
    height: 360px;
}
.plane-seat-selection-item {
    left: calc(50% - 384px);
}
.plane-seat-selection-inner {
    position: absolute;
    top: calc(50% - 1297.5px);
    left: calc(50% - 1062px);
    width: 2124px;
    height: 2595.5px;
}
.plane-seat-selection-child1,
.vector-icon {
    position: absolute;
    top: 400.5px;
    left: 1137.53px;
    width: 73.5px;
    height: 45.34px;
    opacity: 0.4;
}
.plane-seat-selection-child1 {
    left: 1216.5px;
    width: 72.47px;
}
.rectangle-icon {
    position: absolute;
    top: calc(50% - 1025px);
    left: calc(50% - 109.5px);
    width: 219px;
    height: 1903.5px;
}
.seat-icon10 {
    position: relative;
    width: 30px;
    height: 40px;
}
.div2 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.row-number2 {
    position: relative;
    width: 30px;
    height: 32px;
}
.makered .EconBox1{
    background: #050000;
}

.SeatDataCont{
    position: relative;
    display: inline-flex;
    justify-content: center;
    width: 95%;
    flex-direction: row;
    margin-bottom: 15px;
}

.passcnt{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.closeX{
    position: absolute;
    right: 0;
}

.passNames{
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.selectSeat{
    color:#054DAB;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #054DAB;
    background: #FAFCFF;
    display: flex;
    width: 89px;
    height: 32px;
    padding: 16px 32px 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.selectSeatII{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    /*border: 1px solid #054DAB;*/
    background-color: #64BB68;
    color: #FAFCFF ;
    display: flex;
    width: 89px;
    height: 32px;
    padding: 16px 32px 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.minTextSeat{
    color: #000;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.mainTextSeat{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/*.seatClickDis{*/
/*    border-radius: 8px 8px 8px 0;*/
/*    background:  #FAFCFF;*/
/*    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);*/
/*    position: absolute;*/
/*    width:280px;*/
/*    right: 113px;*/
/*    z-index: 1000;*/
/*    color: black;*/
/*    padding: 10px;*/
/*    text-align: center;*/
/*}*/

.makeLine{
    width: 100%;
    height: 1px;
    background: #B1DDB3;
    margin-top: 15px;
    margin-bottom: 15px;
}

.business,
.seat-row-business1 {
    background-color: var(--true-white);
    display: flex;
    align-items: center;
}
.makeRed{
    background: #000000;
}
.seat-row-business1 {
    align-self: stretch;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-5xs);
    justify-content: space-between;
}
.business {
    position: absolute;
    top: calc(50% - 868.5px);
    left: calc(50% - 100px);
    border-radius: var(--br-5xs);
    width: 200px;
    overflow: hidden;
    flex-direction: column;
    padding: 12px 0;
    box-sizing: border-box;
    justify-content: flex-start;
    gap: 12px;
}
.exit-row1 {
    position: absolute;
    top: 1px;
    left: 25px;
    font-weight: 600;
}
.information-icon2 {
    position: absolute;
    top: 0;
    left: 4px;
    border-radius: 2.25px;
    width: 18px;
    height: 18px;
    overflow: hidden;
}
.seat-row-exit-row1 {
    align-self: stretch;
    position: relative;
    background-color: var(--true-white);
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    font-size: var(--font-size-xs);
}
.seat-icon30 {
    position: relative;
    width: 22px;
    height: 32px;
}
.economy,
.seat-row-economy1 {
    background-color: var(--true-white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.seat-row-economy1 {
    align-self: stretch;
    flex-direction: row;
    padding: var(--padding-9xs);
    gap: var(--gap-8xs);
}
.economy {
    position: absolute;
    top: calc(50% - 540.5px);
    left: calc(50% - 100px);
    border-radius: var(--br-5xs);
    width: 200px;
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-5xs) 0;
    box-sizing: border-box;
    gap: 4px;
}
.plane-seat-selection {
    position: relative;
    width: 100%;
    height: 2965px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-sm);
    color: var(--grey-400);
    font-family: var(--font-nunito-sans);
}

.planecont {
    margin: 0 10px;
    padding: 15px;
    /* background-size: unset; */
    background-size: 6000px;
    width: 100%;
    background-repeat: no-repeat;
    background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716008043/JaromTravels/alz7rcdpw974ptlbjmki.svg);
    background-position: center;
    position: relative;
    background-position-x: -2926px;
}

.planecont img {
    width: 100%;
}

.MaCOnt{
   /*padding-top: 100px;*/
}

.AllClassContI{
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
}

.AllClassContII{
    display: inline-flex;
    flex-direction: column;
    gap: 30px;
    /*position: absolute;*/
    /*top: 163px;*/
    /*left: 137px;*/
}
.makecenter{
    /*text-align: center;*/
}


.colClass, .colClass2, .colClass3, .colClass4 {
    border-radius: 8px;
    background: #FFFFFF;
    width: 260px;
}

.busClass, .EconClass {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    flex-wrap: nowrap;
    padding: 12px;
    gap: 12px;
}

.ExitrowCont{
    display: inline-flex;
    text-align: left;
    width: 100%;
}

.ExitRow{
    color:  #7C8DB0;
    text-align: center;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.nubBox{
    color: #7C8DB0;
    text-align: end;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.busClass-Col1, .busClass-Col2, .busClass-Col3, .busClass-Col4, .busClass-Col5, .EconClass-Col6,.EconClass-Col7,.EconClass-Col8, .EconClass-Col9, .EconClass-Col10
{
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.lessthanthree{
    display: inline-flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.middlenum{

}

.makeInvisibleSeat{
    visibility: hidden;
    /*background: none;*/
    /*border: 1px blue dashed;*/
}
.makedisplaynone{
    display: none;
}

.box1 p, .EconBox1 p{
    /*text-align: center;*/
    color: #FFFFFF;
    margin-bottom: 0;
    font-family: Rubik;
    font-weight: lighter;
}

.box1{
    display: inline-flex;
    width: 30px;
    height: 40px;
    background: linear-gradient(180deg, #5CD6C0 0%, #22C3A6 100%);
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}

.adjwidith{
    width: 100%;
}

.box1:hover, .EconBox1:hover{
    background:  linear-gradient(180deg, rgba(246, 170, 198, 0.83) 0%, rgba(252, 154, 170, 0.88) 100%);
    cursor: pointer;
}




.EconBox1{
    align-items: center;
    justify-content: center;
    display: inline-flex;
    background: linear-gradient(180deg, #605DEC 0%, #2A26D9 100%);
    border-radius: 4px;
    width: 22px;
    height: 32px;
    font-size: 7px;
    color: white;

}
.makeBluue{
    background: linear-gradient(180deg, #605DEC 0%, #2A26D9 100%);
    width: 30px;
    height: 40px;
    border-radius: 4px;

}

.extra-leg{
    background: linear-gradient(180deg, #5DEC9F 0%, #2A26D9 100%);
}

.unavailableSeat{
    background:  #E9E8FC;
}

@media (min-width: 425px){
    .colClass, .colClass2, .colClass3, .colClass4{
        border-radius: 8px;
        background: #FFFFFF;
        width: 353px;
    }




}


@media (min-width: 717px){

}
@media (min-width: 760px){
    .planecont {
        margin: 0 10px;
        padding: 30px;
        /* background-size: unset; */
        background-size: 6000px;
        width: 100%;
        background-repeat: no-repeat;
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716008043/JaromTravels/alz7rcdpw974ptlbjmki.svg);
        background-position: center;
        position: relative;
        background-position-x: -2889px;
    }
}



@media (min-width: 991px){

}

@media (min-width: 1024px){
    .planecont {
        margin: 0 10px;
        padding: 30px;
        /* background-size: unset; */
        background-size: 6000px;
        width: 100%;
        background-repeat: no-repeat;
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1716008043/JaromTravels/alz7rcdpw974ptlbjmki.svg);
        background-position: center;
        position: relative;
        background-position-x: -2833px;
    }
}

@media (min-width: 1280px){

}