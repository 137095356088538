.sFooter{
    /*max-height: 100%;*/
    font-family: 'Rubik', sans-serif;
    margin: 0px auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}
.copyRight div{
    margin-top: 10px;
}

.hover:hover{
    color: #3e3eea;
}

.newsletterHeader{
    font-size: 20px;
    font-weight: bold;
    font-family: Rubik;
}

.mimick_h1{
    font-weight: bold;
    font-family: Rubik;
    font-size: 24px;
    margin-bottom: 24px;
}



.copyRight{
    height: 30px;
}

@media(min-width: 300px){

    .mimick_h1{
        font-weight: bold;
        font-family: Rubik;
        font-size: 18px;
        margin-bottom: 18px;
        width: 110%;
    }

    .mimick_h2{
        font-weight: bold;
        font-family: Rubik;
        font-size: 12px;
        margin-bottom: 6px;
    }
    .copyRight{
        height: 30px;
    }

    .aboutUsFooter{
        width: 60%;
    }

    .connectFooter{
        font-size: 18px;
        font-weight: 400;
        margin-top: 24px;
    }
    .subFooter{
        background-color: #82A6D5;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: 16px;
    }

    .footerSocials{
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .subFooterText{
        color: white;
        padding-top: 16px;
        margin: 0 auto;
    }

    .subFooterText h1{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 4px;
    }

    .subFooterText p{
        font-size: 12px;
        font-weight: 400;
        width: 241px;
        margin-bottom: 16px;
    }


    .subForm{

        display: inline-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        width: 314px;
        height: 36px;
        border-radius: 6px;
        margin: 0 auto;
    }
    .subInput{
        background: transparent;
        font-size: 12px;
        width: 219px;
        height: 34px;
        border: 0;
        border-radius: 6px 0 0 6px;
        text-indent: 10px;
    }
    .decoyFooter{
        margin-top: 1px;

        width: 96px;
        height: 34.05px;
        border-radius: 0 6px 6px 0;
    }
    .subButton {
        /* margin-left: 12px; */
        border: 0;
        width: 84.31px;
        height: 28px;
        background: #054DAB;
        border-radius: 2.8125px;
        /* margin-bottom: 52px; */
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        color: #F0F6FE;
    }
    .footerLogo{
        width: 90px;
        height: 47px;
        margin-bottom: 8px;
    }
    .footerText{
        font-size: 10px;
        width: 180px;
        line-height: 12px;
        font-weight: 400;
    }

    .middleSeg{
        width: 42%;
        padding-right: 10px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
    }

    .middleSeg ul li{

    }

    .copyRight{
        font-family: 'Montserrat';
        text-align: center;
    }

    .copyRight div{
        font-size: 12px;
    }

    .footerMain {
        padding-top: 21px;
        display: inline-flex;
        background-color: #010f22;
        color: white;
        width: 100%;
        justify-content: space-around;
    }

    .leftSeg {
        padding-left: 12px;
        padding-right: 8px;
    }




    .middleSeg ul{
        padding: 0;
    }

    .middleSeg ul li{
        list-style: none;
        margin-bottom:8px;
    }

    .rightSeg{
        display: none;
    }

    .middleSeg h1{
        font-size: 16px;
        margin-bottom: 16px;
    }

    /*.contactUsFooter h2{*/
    /*    font-size: 12px;*/
    /*}*/

    .contactUsFooter h2 {
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 6px;
    }

    .contactUsFooter p{
        margin-bottom: 12px;
    }
}

@media(min-width: 760px){
    .newsletterHeader{
        font-size: 26px;
        font-weight: bold;
        font-family: Rubik;
    }
    .mimick_h1{
        font-weight: bold;
        font-family: Rubik;
    }

    .copyRight{
        height: 30px;
    }

    .aboutUsFooter{
        width: 30%;
    }

    .middleSeg {
        display: inline-flex;
        flex-direction: row;
        gap: 39px;
        font-size: 8px;
        padding-left: 37px;
        width: 60%;
    }

    .middleSeg h1{
        font-size: 14px;
    }

    .rightSeg{
        display: flex;
        flex-direction: column;
        width: 140px;
    }

    .footerMain {
        padding: 20px 20px 20px 20px;
        display: inline-flex;
        background-color: #010f22;
        color: white;
        width: 100%;
        justify-content: space-between;
    }

    .footerText{
        font-weight: 400;
        font-size: 8.12px;
        line-height: 10px;
        letter-spacing: 0.29px;
    }

    .rightSeg h1{
        font-size: 14px;
    }

    .contactUsFooter h2{
        font-size: 9px;
    }

    .rightSeg p{
        font-weight: 400;
        font-size: 8.12px;
        line-height: 180%;
        letter-spacing: 0.087px;
    }

    .connectFooter{
        font-size: 13px;
    }

    .footerSocials img{
        width: 13px;
        height: 13px;
    }

    .subFooter{
        padding: 16px 21px 24px 21px;
        flex-direction: row;
    }

    .subFooterText h1{
        font-size: 23px;
    }

    .subFooterText{
        text-align: left;
        padding-top: unset;
    }

    .subFooterText p{
        width: 100%;
        font-size: 12px;
        font-weight: 400;
    }

    .copyRight div{
        font-size: 11px;
    }
}

@media(min-width: 839px){

    .copyRight{
        height: 30px;
    }
    .middleSeg{
        width: 50%;
    }
}

@media(min-width: 1024px){
    .newsletterHeader{
        font-size: 36px;
        font-weight: bold;
        font-family: Rubik;
    }

    .mimick_h1{
        font-weight: bold;
        font-family: Rubik;
        font-size: 24px;
        margin-bottom: 24px;
    }

    .mimick_h2{
        font-weight: bold;
        font-family: Rubik;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .copyRight{
        height: 30px;
    }

    .copyRight div{
        font-size: 16px;
    }

    .middleSeg {
        width: 60%;
        display: inline-flex;
        flex-direction: row;
        gap: 97px;
        padding-right: 30px;
        font-size: 8px
    }

    .subForm {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: white;
        width: 500px;
        height: 50px;
        border-radius: 10px;
        margin: 0 auto;
    }

    .subButton {
        width: 120px;
        height: 40px;
        font-size: 13px;
        margin-left: 105px;
        border-radius: 6px;
    }

    .subInput {
        background: transparent;
        font-size: 16px;
        width: 250px;
        height: 47px;
        border: 0;
        border-radius: 6px 0 0 6px;
        text-indent: 10px;
    }

    .subFooter {
        padding: 24px 21px 43px 21px;
        flex-direction: row;
    }

    .subFooterText h1 {
        font-size: 30px;
    }

    .subFooterText p{
        font-size: 16px;
    }

    .footerText{
        letter-spacing: 0.4px;
        font-size: 11px;
        line-height: 14px;
        width: 189px;
    }

    .connectFooter{
        font-size: 22px;
    }

    .contactUsFooter h2{
        font-size: 14px;
        margin-bottom: 8px;
    }

    .contactUsFooter p{
        margin-bottom: 16px;
    }

    .contactUsFooter h2{

    }

    .footerSocials img{
        width: 22px;
        height: 22px;
    }

    .middleSeg h1{
        font-size: 22px;
        margin-bottom: 24px;
    }

    .middleSeg{
        font-size: 12px;
        line-height: 12px;
        gap: 42px;
    }

    .rightSeg h1{
        font-size: 22px;
        margin-bottom: 24px;
    }

    .rightSeg p{
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        letter-spacing: 0.15px;
    }

    .footerMain{
        padding: 60px 20px 27px 20px;
    }

    .rightSeg{
        width: 203px;
    }

    .middleSeg ul li{
        margin-bottom: 14px;
    }
}

@media(min-width: 1198px){
    .middleSeg {
        font-size: 12px;
        line-height: 12px;
        gap: 63px;
    }

    .copyRight{
        height: 30px;
    }
}

@media(min-width: 1280px){
    .middleSeg {
        display: inline-flex;
        flex-direction: row;
        gap: 97px;
        padding-right: 30px;
        /*font-size: 8px*/
    }

    .copyRight{
        height: 30px;
    }

    .subForm {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        width: 519px;
        height: 64px;
        border-radius: 6px;
        margin: 0 auto;
    }

    .subButton{
        width: 157px;
        height: 48px;
        font-size: 20px;
        margin-left: 87px;
    }

    .subInput {
        background: transparent;
        font-size: 15px;
        width: 260px;
        height: 47px;
        border: 0;
        border-radius: 6px 0 0 6px;
        text-indent: 10px;
    }

    .subFooter {
        padding: 24px 21px 43px 21px;
        flex-direction: row;
        gap: 169px;
    }

    .subFooterText h1 {
        /*font-size: 35px;*/
    }

    .subFooterText p{
        /*font-size: 18px;*/
    }

    .footerMain{
        padding: 60px 40px 27px 40px;
    }
}

@media(min-width: 1440px){
    .copyRight{
        height: 30px;
    }

    .middleSeg {
        display: inline-flex;
        flex-direction: row;
        gap: 97px;
        padding-right: 30px;
        /*font-size: 8px*/
    }

    .subForm {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        width: 519px;
        height: 64px;
        border-radius: 10px;
        margin: 0 auto;
    }

    .subButton {
        width: 157px;
        height: 48px;
        font-size: 20px;
        margin-left: 87px;
        border-radius: 7px;
    }

    .subInput {
        background: transparent;
        font-size: 15px;
        width: 260px;
        height: 47px;
        border: 0;
        border-radius: 6px 0 0 6px;
        text-indent: 10px;
    }

    .subFooter {
        padding: 24px 21px 43px 21px;
        flex-direction: row;
    }

    .subFooterText h1 {
        /*font-size: 35px;*/
    }

    .subFooterText p{
        /*font-size: 20px;*/
    }

    .footerText{
        font-size: 14px;
        width: 240px;
        line-height: 17px;
        letter-spacing: 0.5px;
    }

    .footerLogo{
        margin-bottom: 18px;
    }

    .connectFooter{
        font-size: 24px;
    }

    .middleSeg h1{
        font-size: 24px;
    }

    .middleSeg{
        font-size: 14px;
    }

    .rightSeg p{
        font-size: 14px;
    }

    .rightSeg{
        width: 244px;
    }

    .rightSeg h1{
        font-size: 24px;
    }

    .contactUsFooter p{

    }
}