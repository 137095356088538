.cookiesCont{
    background: #FFF;
    border: #D9DBE9 1px solid;
    padding: 23px;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    box-shadow: 0px 14px 42px rgba(8, 15, 52, 0.06);
}

.onlyat1440{
    display: none;
}

.altcookiescont{
    width: 100%;
    display: inline-grid;
    justify-items: center;
}

.cookiesMainText{
    color:  #6F6C90;
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 21px;
}

.cookiestermsSpan{
    color:  #427AFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
}

.acceptCookies{
    border-radius: 8px;
    background: #054DAB;
    display: flex;
    height: 32px;
    /*padding: 32px 16px 32px 8px;*/
    padding: 8px;
    align-items: center;
    gap: 3px;
    color:  #F0F6FE;
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    line-height: normal;
}

.declineCookies{
    border-radius: 8px;
    border: 1px solid  #054DAB;
    display: flex;
    cursor: pointer;
    width: 108px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px;
    /*padding: 32px 16px 32px 8px;*/
    color: #054DAB;
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cookiesBtn{
    display: inline-flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.mobileCookies{
    color: #170F49;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.textCookies {
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
}

.textCookies img {
   display: none;
}

.acceptCookies img{
    width: 8px;
    height: 8px;
}


@media screen and (min-width: 765px){
    .mobileCookies{
        display: none;
    }

    .textCookies img{
        display: block;
    }

    .cookiesCont {
        flex-direction: row;
        padding: 40px;
        gap: 20px;
        width: 100%;
    }

    .textCookies {
        display: inline-flex;
        flex-direction: row;
        gap: 14px;
        align-items: center;

    }

    .textCookies img{
        width: 50px;
    }

    .cookiesMainText{
        color:  #6F6C90;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0px;
    }
    .cookiestermsSpan{
        color: #427AFF;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .acceptCookies{
        border-radius: 6.8px;
        background: #054DAB;
        display: flex;
        height: 44px;
        /*padding: 32px 16px 32px 8px;*/
        /*padding: 27px;*/
        align-items: center;
        /*gap: 8px;*/
        color:  #F0F6FE;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
        line-height: normal;
    }

    .declineCookies{
        border-radius: 6.8px;
        border: 1px solid  #054DAB;
        display: flex;
        cursor: pointer;
        width: 125px;
        height: 44px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        /*padding: 27px;*/
        /*padding: 32px 16px 32px 8px;*/
        color: #054DAB;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .acceptCookies img{
        width: 16px;
        height: 16px;
    }




}

@media screen and (min-width: 1024px){
    .cookiesMainText{
        color:  #6F6C90;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
    }

    .cookiestermsSpan{
        color:  #427AFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
    }

    .acceptCookies{
        border-radius: 8px;
        background: #054DAB;
        display: flex;
        height: 56px;
        /*padding: 32px 16px 32px 8px;*/
        padding: 30px;
        align-items: center;
        gap: 8px;
        color:  #F0F6FE;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
        line-height: normal;
    }

    .declineCookies{
        border-radius: 8px;
        border: 1px solid  #054DAB;
        display: flex;
        cursor: pointer;
        width: 125px;
        height: 56px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 30px;
        /*padding: 32px 16px 32px 8px;*/
        color: #054DAB;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .cookiesBtn{
        display: inline-flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
    }


    .textCookies{
        display: inline-flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
    }

    .cookiesCont {
        width: 100%;
    }
}

@media screen and (min-width: 1440px){
    .displayNone{
        display: none;
    }

    .cookiesCont {
        width: 82%;
    }

    .onlyat1440{
        display: block;
    }
    .textCookies img {
        width: 79px;
    }
}
