/*body{*/
/*    background: #0a53be;*/
/*}*/
.SignUpBack{
    height: 100vh;
    min-height: 720px;
    background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1678126787/Sign-up_page_hcepwj.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    display: flex;
    padding: 1%;
}
.blueBack .stepperTop{
    display: inline-flex;
    gap: 21px;
    align-items: center;
}
.SignUpStepOne{
    height: 100vh;
    min-height: 720px;
    background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1678245624/step_7_lzhk71.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    display: flex;
    padding: 1%;
    margin: auto;
}

.firstOne{
    font-family: 'Rubik', serif;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(24px, calc(0.833333vw + 18.666667px), 35px);
    line-height: 28px;
    color: #FAFCFF;
}
.stepButtonMargin{
    margin: 72px auto 17px;
}
#passResetButton{
    margin: 28px auto 17px;
}
#passContinueButton{
    margin: 28px auto 110px;
}
#passEmailButton{
    margin: 28px auto 16px;
}
.stepButton{
    display: flex;
    flex-direction: row;
    border: 0px;
    justify-content: center;
    align-items: center;
    padding: 5.59184px;
    gap: 5.59px;
    width: 76%;
    height: 41.94px;
    background: #054DAB;
    border-radius: 3.4949px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
}
.finish{
    margin: 50px auto 17px;
}
.secOne{
    font-family: 'Rubik', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.104847px;
    margin-top: 5px;
    margin-bottom: 24px;
}
.SignUpStepTwo{
    min-height: 720px;
    height: 100vh;
    background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1678245843/step_9_ebq1np.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    display: flex;
    padding: 1%;
}
.passwordDetails{
    font-style: normal;
    font-weight: 600;
    font-size: 7px;
    line-height: 14px;
    color: #8F9AAC;
    text-align: left;
    padding-left: 4%;
    width: 76%;
    margin-top: 5px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 22px;
}
.error-message{
    font-style: normal;
    font-weight: 500;
    font-size: clamp(8px, 2vw, 12px);
    line-height: 14px;
    color: red;
    text-align: left;
    padding-left: 14%;
    margin-top: 5px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    background-color: transparent !important;
}
input:-ms-autofill {
    background-color: transparent !important;
}


.SignUpStepThree{
    min-height: 720px;
    height: 100vh;
    background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1678246044/step_8_geunvl.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    display: flex;
    padding: 1%;
}
.PasswordReset{
    min-height: 720px;
    height: 100vh;
    background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1679430531/Set_New_Password_ovrtad.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    display: flex;
    padding: 1%;
}
#PasswordContinue{
    background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1679567079/Reset_PAssword_successful_b90dxa.svg);
}
#PasswordEmail{
    background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1679567395/Forgot_Password_hlgyvy.svg);
}
.SignUpBack img{
    width: 100%;
    height: 100%;
}
.buttonBlue{
    color: white;
    display: flex;
    flex-direction: row;
    border: 0px;
    justify-content: center;
    align-items: center;
    background: #054DAB;
    border-radius: 8px;
}
.menuSize{
    /*margin-top: 12px;*/
    margin-bottom: 81px;
    margin-left: -16px;
    width: 126px;
    height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 16px 32px 16px 30px;
    gap: 10px;
}
.blueBack{
    width: 100%;
    background: #021A39;
    border-radius: 5px;
    margin: auto;
    padding-top: 26px;
    max-width: 720px;
    padding-bottom: 50px;
    text-align: center;
    color: white;
}
.blueBack .first{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 17.5px;
    line-height: 21px;
    letter-spacing: 0.125px;

}
.blueBack .secx{
    font-family: 'Rubik', serif;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.075px;
    color: #D2D7DE;
    margin-bottom: 24px;
}
.blueBack .socialButtons{
    display: flex;
    flex-direction: row;
    border: 0px;
    justify-content: center;
    align-items: center;
    padding: 5.59184px;
    gap: 5.59px;
    width: 76%;
    height: 41.94px;
    background: #FAFCFF;
    border-radius: 3.4949px;
    font-style: normal;
    font-weight: 600;
    font-size: clamp(14px, 2vw, 20px);
    line-height: 16px;
    color: #010205;
    margin: auto auto 17px;
}
.blueBack img{
    width: 17px;
    height: 17px;
}
.blueBack hr{
    width: 111.84px;
    height: 0px;
    background: #D2D7DE;
    border: 0.698979px solid #D2D7DE;
    display: inline-flex;
}
.blueBack .or{
    margin-left: 10px;
    margin-right: 10px;
}
.blueBack .liner{
    margin-bottom: 22px;
    display: inline-flex;
    align-items: center;
}
.blueBack .thi{
    width: 76%;
    font-family: 'Rubik', serif;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(10px, 2vw, 13px);
    line-height: 13px;
    color: #E4E7EB;
    text-align: left;
    margin-left: 12%;
    margin-bottom: 40px;
}
.blueBack .thi span{
    color: #8E9AAC;
}
.blueBack-fort{
    font-family: 'Rubik', serif;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(12px, 2vw, 16px);
    line-height: 13px;
    color: #E4E7EB;
    text-align: left;
    margin-left: 12%;
}
#emailNote{
    text-align: center;
    margin-left: auto;
    margin-bottom: 90px;
}
.blueBack .fort span{
    color: #054DAB;
}
.SignUpStepOne .cancelButton{
    position: relative;
    width: 40px;
    height: 40px;
    left: 54%;
    top: -36%;
}
.SignUpStepTwo .cancelButton{
    position: relative;
    width: 40px;
    height: 40px;
    left: 53%;
}
.SignUpStepThree .cancelButton{
    position: relative;
    width: 40px;
    height: 40px;
}
#SignUpBack-cancelButton{
    position: relative;
    width: 40px;
    height: 40px;
    left: 40%;
    top: 0%;
}
.PasswordReset .cancelButton{
    position: relative;
    width: 40px;
    height: 40px;
    left: 36%;
    top: -27%;
}
.PasswordReset .backButton{
    position: relative;
    width: 24px;
    height: 24px;
    right: 35%;
    top: -21%;
}
#passkey{
    width: 40px;
    height: 40px;
}
#wrd-set{
    font-size: 24px;
    margin-bottom: 4px;
    margin-top: 43px;
}
a{
    text-decoration: none;
}
.img-con{
    margin-top: 32px;
    margin-bottom: 32px;
}
#passRight{
    margin-top: 20px;
}
#passRight_{
    font-size: 12px;
    line-height: 14px;
}
.stepperTop .stepImg{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 196px;
    height: 14px;
}
.SignUpStepOne .backButton{
    position: relative;
    width: 24px;
    height: 24px;
    right: 78%;
    top: -14%;
}
.SignUpStepTwo .backButton{
    position: relative;
    width: 24px;
    height: 24px;
    right: 6%;
    top: 0%;
}
#tip{
    margin-top: 5px;
}
.SignUpStepThree .backButton{
    position: relative;
    width: 24px;
    height: 24px;
    top: 0%;
}
.SignUpStepThree .profile_picture{
    width: 45px;
    height: 45px;
    border-radius: 30px;
}
.SignUpStepThree .profileDiv{
    width: 76%;
    display: inline-flex;
    text-align: start;
    align-items: center;
    margin: auto auto 23px;
}
.profileDiv button{
    margin-left: 17px;
    font-family: 'Rubik', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.175064px;
    color: #FAFCFF;
    background: #054DAB;
    border-radius: 1.5px;
    width: 82px;
    height: 18px;
    border: 0;
}
@media (min-width: 760px) {
    .error-message{
        text-align: left;
        padding-left: 14%;
        margin-top: 5px;
    }
    .SignUpBack {
        background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1681838878/Sign-up_page_wqyrt8.svg);
        padding: 23%;
        min-height: 950px;
    }
    .blueBack{
        margin-top: unset;
    }
    .SignUpStepOne{
        background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1681841693/step_1_ws0bxr.svg);
        padding: 23%;
        min-height: 950px;
    }
    .SignUpStepTwo{
        background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1681842026/step_2_r27la3.svg);
        padding: 23%;
        min-height: 950px;
    }
    .SignUpStepThree{
        background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1681844729/step_3_rolrta.svg);
        padding: 23%;
        min-height: 950px;
    }
    .PasswordReset{
        background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1681862390/Set_New_Password_egs7yd.svg);
        padding: 23%;
        min-height: 950px;
    }
    #PasswordContinue{
        background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1681863440/Reset_PAssword_successful_anxy4n.svg);
    }
    #PasswordEmail{
        background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1681863938/Forgot_Password_ezi6r5.svg);
    }
    .stepperTop .stepImg{
        width: 227px;
        height: 16px;
    }
}

@media (min-width: 1024px){
    .error-message{
        padding-left: unset;
        width: 392px;
        margin: 11px auto auto;
    }
    .blueBack .thi{
        line-height: 15px;
        width: 392px;
        margin: auto auto 40px;
    }
    .SignUpBack {
        padding: 18% 8% 0 42%;
        min-height: 1075px;
    }
    .SignUpStepOne{
        padding: 18% 8% 0 42%;
        min-height: 1075px;
    }
    .SignUpStepTwo{
        padding: 18% 8% 0 42%;
        min-height: 1075px;
    }
    .SignUpStepThree{
        padding: 18% 8% 0 42%;
        min-height: 1075px;
    }
    .PasswordReset{
        padding: 18% 8% 0 42%;
        min-height: 1075px;
    }
    .passwordDetails{
        width: 392px;
        font-size: 10px;
    }
    .SignUpStepThree .profileDiv{
        width: 392px;
    }
    .blueBack .socialButtons{
        width: 392px;
        height: 60px;
        border-radius: 5px;
        margin-bottom: 32px;
    }
    .blueBack-fort{
        width: 392px;
        margin: auto;
    }
    .stepButton{
        width: 392px;
        height: 60px;
        border-radius: 5px;
        font-size: 20px;
    }
    .secOne{
        font-size: 17px;
        line-height: 19px;
    }
    .stepperTop .stepImg{
        width: 312px;
        height: 86px;
    }
    .blueBack .stepperTop {
        display: inline-flex;
        gap: 18px;
        align-items: center;
    }

}

@media (min-width: 1400px) {
    .SignUpBack {
        min-height: 1255px;
        max-width: 1440px;
        padding-left: unset;
        padding-right: unset;
        margin: auto;
    }
    .SignUpStepOne{
        min-height: 1255px;
        max-width: 1440px;
        padding-left: unset;
        padding-right: unset;
        margin: auto;
    }
    .SignUpStepTwo{
        min-height: 1255px;
        max-width: 1440px;
        padding-left: unset;
        padding-right: unset;
        margin: auto;
    }
    .SignUpStepThree{
        min-height: 1255px;
        max-width: 1440px;
        padding-left: unset;
        padding-right: unset;
        margin: auto;
    }
    .PasswordReset{
        min-height: 1255px;
        max-width: 1440px;
        padding-left: unset;
        padding-right: unset;
        margin: auto;
    }
    /*.blueBack{*/
    /*    padding-left: 64px;*/
    /*    padding-right: 64px;*/
    /*    width: 723px;*/
    /*}*/
}