html, #root{
    height: 0;
    /*width: fit-content;*/
}
.blueDot{
    width: 9px;
}

.makeRes input[type="radio"]{
    width: 20px;
    height: 20px;
}

.garantt{
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;
}


.garaFormLab {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
}



.garaTextCont{
    display: inline-flex;
    flex-direction: column;
}


.HeaderGaratext{
    color: #000;
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}
.grantPrice{
    color: #0172CB;
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
}


.garantFormCont{
    border-radius: 8px;
    padding: 23.5px 24px 24.5px 24px;
    border: 2px solid  #C0C8D4;
    background: #FAFCFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);

    margin-bottom: 20px;
}

.Garatext {
    color: #539C57;
    padding-left: 0;
    margin-bottom: 0;
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.coRed{
    color: #FC4E52;

    /* Body 2 */
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.GarantForm {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /*gap: 20px;*/

}

.SSFFeatuer ul.ticketHov{
    position: absolute;
    top: 70px;
    left: 0;
    width: 32%;
    background: #2E3033;
    padding: 10px;
    text-align: start;
    list-style-type: disc;
    display: inline-flex;
    /*padding: 7.699px 7.197px 7.701px 6.603px;*/
    justify-content: center;
    align-items: flex-start;
}

.SSFFeatuer .ticketHov li{
    color: #F7FAFF;
    font-family: Rubik;
    font-size: 9.6px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
    line-height: 9.6px; /* 100% */
}

.tripsum{
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.firstFlex, .secFlex, .thirdFlex{
    width: 33.33%;
}

.thirdFlex{
    text-align: end;
}
.firstFlex{
    text-align: start;
}

.secFlex{
    display: inline-flex;
    flex-direction: row;
}

.timeCode{
    color: #6D8494;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
}

.timecode2{
    color: #234;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.airportName{
    color: #6D8494;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px; /* 180% */
}
.SumArrive{
    color: #F50000;
    text-align: right;
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 180% */
}
.sumdest{
    color: #6D8494;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.sumdestBold{
    color: #234;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

hr:not([size]).myhrline{
    border: none;
    background: #EAF0F3;
    height: 1px;
    width: 100%;
}

.paymentSumCont{
    padding: 20px 20px 0 20px;
}

.radioReg{
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}

.labheadercont {
    display: inline-flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
}


.useless{
    /*transform: scale(1);*/
    /*transform-origin: top left;*/
    /*position: relative;*/
    /*display: grid;*/
    /*justify-items: center;*/
}

.InsuranceHeader{
    color: #252A31;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}

.subTextImg{
    color: #252A31;
    display: inline-flex;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 6px;
    border-top: 3px solid #0172CB;
    border-right: 1px solid #0172CB;
    border-bottom: 1px solid #0172CB;
    border-left: 1px solid #0172CB;
    background: #E8F4FD;
    padding: 13px;
    margin-bottom: 12px;
    align-items: center;
}
.subTextImg img{
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.insuranceRadio{
    border-radius: 2px;
    border: 1px solid #E8EDF1;
    padding: 13px 17px;
    margin-bottom: 17px;
}

.InsList{
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 17px;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 18px;
}

.InsList li{
    display: inline-flex;
    flex-direction: row;
    /*gap: 5px;*/
    width: 100%;
    align-items: center;
}

.liTextIn{
    color: #2E3033;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.InsList img{
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.TravHeader{
    color: #252A31;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.insPrice{
    color: #00A991;
    font-family: Segoe UI;
    font-size: 14.648px;
    font-style: normal;
    font-weight: 400;
    /*line-height: 20px; !* 136.533% *!*/
}


.makesmall{
    font-size: 10px;
}

.formContainer .myinput:focus{
    border: #0a53be solid 1px;
}

.priceBag{
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
}

.popular{
    color: #FFF;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    border-radius: 12px;
    background: #0172CB;
    padding: 5.5px 9.52px 5.5px 8px;
}



/*.formContainer .myinput:invalid{*/
/*    !*border: 1px solid #FC4E52;*!*/
/*    border: unset;*/
/*}*/






@media (min-width: 300px){
    .seatClickDis {
        border-radius: 8px 8px 8px 0;
        background: #FAFCFF;
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
        position: absolute;
        width: 280px;
        right: 11px;
        z-index: 1000;
        color: black;
        padding: 10px;
        text-align: center;
    }
    .flightItineraryContainer{
        /*width: 320px;*/
    }

    .mobile{
        display: block;
    }

    .tab{
        display: none;
    }

    .desktop{
        display: none;
    }

  .tesst .greenNotice{
      padding: 8px 40px;
  }

    .Seatmapmaincont{
        text-align: center;
        padding: 35px 26px 36px 26px;
        border-radius: 8px 8px 0px 0px;
        border-bottom: 1px solid  #CCCED0;
        background: #FFF;
    }

    .unavSeatOpCont {
        padding: 20px;
        display: inline-flex;
        justify-items: center;
        width: 100%;
        justify-content: space-between;
        /* align-content: center; */
        align-items: center;
        flex-wrap: nowrap;
    }

    .RandomSeat label{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }


    .titleRS{
        color: #000;
        text-align: center;
        width: 100%;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 6px;
    }

    .RSImg{
        margin-bottom: 10px;
    }

    .titleRSPrice{
        color: #000;
        text-align: center;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }

    .RandomSeat{
        margin-bottom: 10px;
        border-radius: 8px;
        background: #FFF;
        width: 164px;
        padding: 15px;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
    }

    .unavSeatOp{
        display: inline-flex;
        flex-direction: column;
    }

    .RS{
        float: right;
    }

    .SeatMapUnav{
        /*width: 63%;*/
        color: #000;
        text-align: center;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        margin-bottom: 9px;
        font-weight: 700;
        line-height: normal;
    }

    .PreffOption{
        color: #000;
        text-align: center;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Bus, .extra, .Econ, .Ava{
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .dirTrip{
        color: #000;
        text-align: left;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .seatStatus{
        padding: 18px;
        display: inline-flex;
        flex-direction: column;
        gap: 10px;
    }

    .EconAva, .busExtra{
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }

    .contBtn .visa_casing{
        margin-bottom: unset;
    }

    .contBtn{
        width: 100%;
        margin-bottom: 87px;
        display: inline-flex;
        padding: 18px 37px;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 8px 8px;
        border-bottom: 1px solid  #CCCED0;
        background: #FFF;
        box-shadow: 4px 0px 16px 0px rgba(0, 0, 0, 0.06);
    }

    .Box1Text{
        color: #73787F;
        text-align: center;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .seating{
        margin-bottom: 10px;
        color: #000;
        text-align: left;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .Seat1of3{
       text-align: left;
        padding: 32px 86px 32px 43px;
        border-radius: 8px 8px 0px 0px;
        border-bottom: 1px solid  #CCCED0;
        background: #FFF;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
    }

    .planeSeat{
        height: 720px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-bottom: 20px;
    }

    .saveStanFlexi{
        width: 100%;
        /*margin: 10px;*/
    }

    .EcoBus {
        width: 300px;
        margin: 92px auto;
    }

    .ecoSeatImg {
        display: inline-flex;
        justify-content: center;
        align-items: stretch;
    }

    .ecoCont{
        margin: 24px 6px;
        display: inline-flex;
        flex-direction: column;
    }

    .EcoHeadText{
        color: #73787F;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }


    .EcoHeadCont{
        margin-top: 15px;
        margin-bottom: 8px;
        justify-content: flex-start;
        gap :20px;
        display: inline-flex;
        flex-direction: row;
    }

    .bussLine {
        opacity: 0.5;
        background:#605DEC;
        width: 20px;
        height: 3px;
        margin-top: 10px;
        /*border: 1px solid #605DEC;*/
    }

    .makeGreen{
        background:#5CD6C0;
        width: 20px;
        height: 3px;
        margin-top: 10px;
        /*border: 1px solid #605DEC;*/
    }


    .featuresLists{
        display: inline-flex;
        list-style: none;
        margin: 0;
        padding: 0;
        color: #6E7491;
        font-family: Rubik;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        flex-direction: column;
        align-items: flex-start;
    }

    .featuresLists li{
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 9px;
        gap: 10px;
    }

    .ecoSubText{
        color:  #7C8DB0;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .EcoSelect{
        padding: 2px;
        text-align: center;
        font-family: Rubik;
        font-size: 10.5px;
        font-style: normal;
        font-weight: lighter;
        line-height: 13.5px; /* 128.571% */
        color: white;
        border-radius: 3px;
        background:  linear-gradient(180deg, #605DEC 0%, #2A26D9 100%);
    }

    .selectedCard{
        border-radius: 8px;
        border: 1.2px solid #054DAB;
        background: #FAFCFF;
        box-shadow: 0px 2.3999950885772705px 9.599980354309082px 0px rgba(0, 0, 0, 0.06);
    }

    .mainContSSF{
        /*border: black 1px solid;*/
        position: relative;
        width: 100%;
        display: inline-flex;
        /*margin: 10px;*/
        border-radius: 8px;
        padding: 10px 14px 20px 14px;
        background: #FFF;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
        flex-direction: row;
        margin-bottom: 24px;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: nowrap;
    }

    .SSFFeatuer ul{
        display: inline-flex;
        flex-direction: column;
        list-style: unset;
        margin: 0;
        padding: 0;
    }

    .ssfUnder{
        text-decoration-line: underline;
    }

    .priceSSF{
        text-align: right;
        margin-bottom: 16px;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 120% */
    }

    .intextSSF{
        width: 100%;
    }

    .row1SSF, .row2SSF{
        width: 50%;
    }

    .ssfUnder{
        width: unset;
    }

    .ssfMiddle{
        margin: 8px 0;
    }



    .SSFFeatuer ul li {
        color: #252A31;
        text-align: left;
        font-family: Rubik;
        display: inline-flex;
        gap: 4px;
        flex-direction: row;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        align-items: center;
        justify-content: flex-start;
    }

    .SSFTitle{
        padding-bottom: 16px;
        color: #000;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .recom{
        position: absolute;
        right:13px;
        top: -15px;
        padding: 5px 9px 5px;
        border-radius: 12px;
        background: #0172CB;
        color: white;
        font-size: 10px;
        text-align: center;
    }

    .row2SSF{
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        flex-wrap: nowrap;
        width: 37%;
    }

    .btnSSF {
        cursor: pointer;
        text-align: center;
        padding: 8px;
        border-radius: 8px;
        font-size: 14px;
        border: 1px solid #054DAB;
    }


    .yourTrip, .headingItine{
        color: #234;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28.8px;
    }

    .fightCusHeaderCont2{
        border-bottom: 1px solid #CCCED0;
        padding: 0 5px;
        margin-bottom: 10px;
    }

    .fightCusHeaderCont1{
        padding:0 5px;
    }

    .closeUp{
        display: inline-flex;
        width: 100%;
        gap: 14px;
        justify-content: flex-start;
        align-items: center;
    }

    .flightCustomisation {
        padding: 6px;
        width: 312px;
    }

    .fightCusHeaderHead{
        color: #2C313A;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 21.6px; /* 120% */
        padding: 10px 0;
    }

    .cancelTrip{
        color: #2E3033;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 21.6px; /* 135% */
    }

    .cancelTripText{
        color: #4F5E71;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 18px;
    }

    .termsCondCont input{
        width: 18px;
        height: 18px;
    }

    .termsCond {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }

    .termsCondCont {
        margin-bottom: 32px;
        display: inline-flex;
        flex-direction: row;
        color: #6D8494;
        font-family: Rubik;
        font-size: 10px;
        font-style: normal;
        gap: 10px;
        font-weight: 400;
        line-height: 10px;
        align-items: flex-start;
    }

    .termsSpan{
        color:  #2F6BB9;
        font-family: Rubik;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .fullcont {
        /*display: grid;*/
        /*width: 100%;*/
        /*justify-items: center;*/
    }

    .travellerInfo {
        margin-top: 60px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 10px;
    }

    .bookinsummNdflightItinerary {
        display: inline-flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .main_cont{
        width: 100%;
        display: flex;
        /* align-items: center; */
        /* align-content: center; */
        justify-content: center;
    }


    .progressTrackerCont{
        background: #F2F7FE;
        margin: 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .greenNotice{
        margin: 0;
        color: white;
        padding: 8px 16px;
        border-radius: 2px;
        background:  #01C9A0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;text-align: center;
    }

    .greenNoticeSpan{
        font-weight: 700;
    }

    .bookingSum{
        border-radius: 3px;
        border: 1px solid #DEE2E6;
        background: #FFF;
        box-shadow: 0px 8px 35px 0px rgba(73, 80, 87, 0.05);
        margin: 12px;
    }

    .SupportContainer{
        margin: 12px;
        border-radius: 3px;
        border: 1px solid #DEE2E6;
        background: #FFF;
        box-shadow: 0px 8px 35px 0px rgba(73, 80, 87, 0.05);
    }

    .bookingSumNdSupport {
        display: inline-flex;
        flex-direction: column;
        /*!*width: 50%;*!*/
        /*!*width: 310px;*!*/
        /*!*height: 90vh;*!*/
        /*position: sticky;*/
        /*top: 120px;*/
        /*padding-bottom: 20px;*/
    }

    .bookingSum{
        display: inline-flex;
        flex-direction: column;
    }

    .bookingheader{
        color: #234;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 21.6px;
        border-radius: 3px 3px 0px 0px;
        border-bottom: 1px solid #DDEAF0;
        padding: 15px 15px 13.594px 16px;
    }

    .bookingTextCont{
        padding:0 20px 20px 20px;
        display: inline-flex;
        flex-direction: column;
    }

    .bookingText{
        color: #234;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .priceTitle{
        color: #6D8494;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .bookingSubText, .titleNdPrice{
        padding-top: 10px;
    }

    .priceFare{
        color: #234;
        font-family: Rubik, 'system-ui';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }
    .naira{
        font-family: 'Montserrat', Roboto, serif;
        display: none;
    }
    .titleNdPrice{
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
    }



    .boldit{
        font-weight: 500;
    }

    .unique1{
        color: #6D8494;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .unique2{
        color: #234;
        text-align: right;
        font-family: Rubik, 'system-ui';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .readNotice{
        padding: 4px 17.641px 4px 16.359px;
        border-radius: 2px;
        background: #FFF5F2;
        color: #F50000;
        text-align: center;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
    }

    .readNotice img{
        padding-right: 17px;
    }

    .SupportContainer {
        flex-direction: row;
        display: inline-flex;
        padding: 13px;
        align-items: flex-start;
    }

    .SupportContainer img{
        padding-right: 12px;
    }
    .supportHeader{
        color: #234;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 11.136px;
        margin-bottom: 10px;
    }

    .supportSubText{
        color: #6D8494;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        width: 55%;
    }

    .bolden{
        color: #6D8494;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 12.18px;
    }

    .moreInfoContainer2{
        /*margin: 10px;*/
        /*border-radius: 8px;*/
        /*background: #FFF;*/
        /*padding: 16px 8px 0px 8px;*/
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    }

    .moreInfoContainer2 .more-info-Booking .itenery{
        margin-top: 0;
    }

    .moreInfoContainer2 .more-info-Booking{
        max-height: unset;
        overflow: unset;
        z-index: 0;
    }

    .yourTrip, .headingItine{
        display: none;
    }

    .whoIsTravText{
        color:  #2C313A;
        font-family: Rubik;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 21.6px;
        /*margin-bottom: 10px;*/
    }

    .whoIsTravSecContText{
        color: #73787F;
        font-family: Rubik;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .whoIsTravSecCont{
        display: inline-flex;
        flex-direction: row;
        align-items: center;
    }

    .whoIsTrav {
        /*width: 380px;*/
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 14px 14px;
        border-radius: 3px 3px 0 0;
        border-bottom: 1px solid #DDEAF0;
        align-items: center;
    }

    .formContainer {
        margin: 8px;
        /*padding: 0 0 0 10px;*/
        /*display: grid;*/
        /*align-items: center;*/
        /*!*width: 340px;*!*/
        /*justify-items: flex-start;*/
    }

    .blueNotice{
        /*width: 380px;*/
        margin-top: 8px;
        padding: 8px 39.612px 8px 20px;
        border-radius: 2px;
        background: #DEEBFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }

    .blueNoticeText{
        color: #054DAB;
        font-family: Rubik;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }

    .priContText {
        color: #2C313A;
        font-family: Rubik;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
    }

    .priContBtn .mainBtn{
        margin-top: 0;
        display: flex;
        border-radius: 4px;
        background: #054DAB;
        padding: 8px 5px 8px 5px;
        color: #FAFCFF;
        text-align: center;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        border: none;
    }

    .priCont {
        gap: 8px;
        margin-left: 16px;
        margin-top: 8px;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 80%;
    }


    .Maincontainer {
        /*display: inline-flex;*/
        /*justify-items: center;*/
        /*justify-content: center;*/
        /*flex-direction: column;*/
        /*align-items: center;*/
    }
    .pheww{
        display:flex;
        flex-direction: column;
    }

    .oga_o{
        /*display: grid;*/
        /*justify-items: center;*/
    }

    .mainFormCont {
        padding: 15px;
        width: 100%;
        margin-top: 16px;
        background: #F2F7FE;
        /*display: flex;*/
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .mainFormCol1{
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        align-items: stretch;
    }

   .mainFormCol1 #inputTop, .mainFormCol2 #inputTop{
        margin-top: unset;
    }

   .SecCol{
       display: inline-flex;
       flex-direction: row;
       width: 100%;
       gap: 16px;
       align-items: center;
       justify-content: space-between;
   }

    .mainFormCol2 #inputTop{
        width: 100%;
    }

    .mainFormCol2{
        width: 100%;
        display: inline-flex;
        flex-direction: column;
    }

    .mainFormCol3 #inputTop{
        margin-top: unset;
    }

    .colAll #inputTop{
        margin-top: unset;
    }

    .colAll{
        width: 100%;
        display: inline-flex;
        flex-direction: column;
    }

    .mainForm2 {
        width: 100%;
        background: #F2F7FE;
        padding: 15px;
    }

    .passDetailsText{
        margin: 16px 0;
        color: #73787F;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    .redNotice2{
        margin-bottom: 24px;
        padding: 8px 36.206px 8px 20px;
        border-radius: 2px;
        background: #FFF5F2;
        width: 380px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }

    .redNotice2Text{
        color:#FC4E52;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 175% */
    }

    .checkboxw label{
        color:  #73787F;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }

    .checkboxwSpan{
        color: #73787F;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }

    #checkboxw{
        width: 18px;
        height: 18px;
        border-radius: 2px;
        border: 1px solid #ADB5BD;
        background:  #FAFCFF;
        margin-right: 10px;
    }

    .checkboxw {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 17px 0;
    }

    .whereText{
        /*text-align: center;*/
        color: #2C313A;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21.6px; /* 154.286% */
    }

    .whereCont{
        display: none;
    }

    .mainForm3{
        padding: 15px;
        display: inline-flex;
        flex-direction: column;
        width: 100%;
    }

    .where{
        padding: 9px;
        border-radius: 3px 3px 0px 0px;
        border-bottom: 1px solid #DDEAF0;
    }

    .confirm{
        width: 100%;
        border-radius: 3px;
        border: 1px solid #DEE2E6;
        background:  #F2F7FE;
        box-shadow: 0px 8px 35px 0px rgba(73, 80, 87, 0.05);
        padding: 1px 1px 16px 1px;
        margin-bottom: 36px;
    }

    .CarryBaggage {
        display: flex;
        margin-bottom: 24px;
        padding: 17px;
        border-radius: 4.8px;
        border: 1.2px solid #E6F0FE;
        background: #FAFCFF;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
    }

    .CarryBaggageText{
        color: #2E3033;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.96px;
        margin-bottom: 5px;
    }

    .CarryBaggageSubText{
        color: #4F5E71;
        font-family: Rubik;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        margin-bottom: 15px;
    }

    .clickbag{
        margin-bottom: 10px;
        border-radius: 8px;
        padding: 14.799px 13.802px 13.401px 5.398px;
        background: #FFF;
        box-shadow: 0px 0.5999987721443176px 2.3999950885772705px 0px rgba(37, 42, 49, 0.12), 0px 0px 1.1999975442886353px 0px rgba(37, 42, 49, 0.16);
    }

    .checkboxxBag {
        height: 100%;
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        /*align-items: center;*/
    }

    .checkboxxBag label{
        padding-left: 5px;
        display: inline-flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        align-items: flex-end;
    }

    .presItm{
        color: #000;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .description{
        color: #4F5E71;
        text-align: center;
        font-family: Rubik;
        font-size: 10px;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 9.6px; /* 96% */
    }

    .checkboxxBag input{
        padding-right: 24px;
    }

    .dimen{
        color: #4F5E71;
        font-family: Rubik;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 9.6px; /* 120% */
    }

    .priceBagMain{
        color: #000;
        font-family: Rubik, 'system-ui';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 14.4px;
    }

    /*#iffor{*/
    /*    width: 15px;*/
    /*    height: 15px;*/
    /*}*/

    .BaggImg{
    }

    .clickbagSelected{
        border-radius: 8px;
        border: 1.2px solid #054DAB;
        background:  #FAFCFF;
        box-shadow: 0px 2.3999950885772705px 9.599980354309082px 0px rgba(0, 0, 0, 0.06);
    }

    .presItm{
        margin-bottom: 5px;
    }


    .ItemsAmt {
        text-align: left;
        display: inline-flex;
        gap: 14px;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .checkedBag {
        color: #252A31;
        font-family: Rubik;
        font-size: 9px;
        gap: 5px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 54px;
    }

    .bookMorePass {
        border-radius: 1.8px;
        border: 0.6px solid #E8EDF1;
        background: #FFF;
        padding: 15px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .bookMorePassText{
        color: #252A31;
        font-family: Rubik;
        font-size: 10.8px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.4px; /* 133.333% */
    }

    .bookMorePassBtn{
        border-radius: 1.8px;
        background:  #CDDBEE;
        color:  #010F22;
        text-align: center;
        font-family: Rubik;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 9px;
        padding: 8.7px 15.828px 8.7px 15.6px;
    }
}

@media (min-width: 425px) {
    .flightCustomisation {
        padding: 8px;
        width: 400px;
    }

    .seatClickDis {
        border-radius: 8px 8px 8px 0;
        background: #FAFCFF;
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
        position: absolute;
        width: 280px;
        right: 113px;
        z-index: 1000;
        color: black;
        padding: 10px;
        text-align: center;
    }
}

@media (max-width: 760px) {




    .flightItineraryContainer{
        /*width: 380px;*/
    }

    .width-adj {
        display: none;
        /* Add other styles for screens <= 760px */
    }


}

@media (min-width: 760px){

    .SSFFeatuer ul li {
        color: #252A31;
        text-align: left;
        font-family: Rubik;
        display: inline-flex;
        gap: 4px;
        flex-direction: row;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        align-items: center;
        justify-content: flex-start;
    }

    .btnSSF {
        cursor: pointer;
        text-align: center;
        padding: 8px;
        border-radius: 8px;
        font-size: 16px;
        border: 1px solid #054DAB;
    }

    .makesticky{
        position: sticky;
        top: 50px;
        /*height: 50vh;*/
    }

    .CarryBaggage {
        padding: 17px;
    }

    .mobile{
        display: none;
    }

    .tab{
        display: block;
    }

    .desktop{
        display: none;
    }

    .flightItineraryContainer{
        /*width: 380px;*/
    }

    .insFlexx{
        display: inline-flex;
        flex-direction: row;
    }

    .makeRes input[type="radio"]{
        width: 12px;
        height: 12px;
    }
    .labheadercont img{
        width: 12px;
        height: 12px;
    }

    .tesst .greenNotice {
        padding: 9px 234px;
    }

    .makeRes {
        display: inline-flex;
        flex-direction: column;
        /*width: 127.333%;*/
        align-items: flex-start;
    }

    .subTextImg {
        color: #252A31;
        display: inline-flex;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 6px;
        border-top: none;
        border-right: none;
        border-bottom: none;
        border-left: none;
        background: none;
        padding: 13px;
        margin-bottom: 12px;
        align-items: center;
    }

    .liTextIn {
        color: #4F5E71;
        font-family: Rubik;
        font-size: 8.4px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .InsList img {
        width: 5.586px;
        height: 4.727px;
        margin-right: 3px;
    }

    .TravHeader {
        color: #252A31;
        font-family: Rubik;
        font-size: 8.4px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .insPrice {
        color: #2E3033;
        font-family: Segoe UI;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .insuranceRadio {
        border-radius: 2px;
        border: 1px solid #E8EDF1;
        padding: 13px 17px;
        margin-bottom: 17px;
        display: inline-flex;
        flex-direction: row;
        gap: 5px;
    }
    .radioReg {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        width: 112%;
        align-items: flex-start;
    }

    .labheader {
        line-height: 8px;
        text-align: center;
    }

    .labheadercont {
        display: inline-flex;
        align-items: flex-start;
        flex-direction: row;
        gap: 10px;
    }
    .RandomSeat {
        margin-bottom: 10px;
        border-radius: 8px;
        background: #FFF;
        width: 126px;
        padding: 6px;
    }

    .titleRS{
        font-size: 14px;
    }

    .titleRSPrice{
        font-size: 13px;
    }

    .unavSeatOp {
        flex-direction: row;
        gap: 10px;
    }

    .Box1Text {
        font-size: 10px;
    }

    .EconAva, .busExtra {
        display: inline-flex;
        flex-direction: row;
        align-items: flex-start;
        /*gap: 15px;*/
    }

    .SSFFeatuer ul li{
        font-size: 12px;
    }

    .SSFTitle{
        font-size: 15px;
    }

    .btnSSF{
        font-size: 14px;
    }


    .priceSSF{
        font-size: 18px;
    }

    .ecoSeatImg img{
        width: 144px;
    }

    .EcoSelect{
        font-size: 9px;
    }

    .EcoHeadText{
        font-size: 12px;
    }

    .featuresLists li{
        margin-top: 2px;
    }

    .featuresLists{
        font-size: 10px;
    }

    .ecoSubText{
        font-size: 11px;
    }

    .EcoBus {
        gap: 40px;
        display: inline-flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
    }

    .flightCustomisation{
        width: 60%;
        padding: 0 16px;
    }

    .fightCusHeaderHead{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.96px;
    }

    .fightCusHeaderCont1{
        padding: unset;
    }

    .fightCusHeaderCont2{
        margin-bottom: 19px;
    }

    .cancelTripText{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }

    .cancelTrip{
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.96px;
    }

    .termsCond .visa_casing{
        width: 50%;
    }

    .termsCond {
        display: inline-flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .termsCondCont{
        margin-bottom: unset;
        width: 50%;
    }

    /*.confirm{*/
    /*    background: transparent;*/
    /*}*/

    .Maincontainer{
        /*align-items: flex-start;*/
    }

    .mainForm3 #inputTop{
        margin-top: unset;
    }

    .mainForm3 {
        padding: 15px;
        display: inline-flex;
        gap: 19px;
        /*flex-direction: row;*/
        align-items: flex-start;
        justify-content: center;
    }

    .where {
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .whereContText{
        color:  #73787F;
        font-family: Rubik;
        font-size: 7.2px;
        font-style: normal;
        font-weight: 400;
        line-height: 10.8px;
    }

    .whereCont {
        display: inline-flex;
        align-items: center;
    }

    .checkboxw {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        margin: 17px 0;
    }

    .mainForm2{
        background: transparent;
    }



    .colAll .visa_input{
        width: 118px;
    }

    .colAll .visa_inputField label {
        font-size: 10px;
        width: 117%;
    }

    .colAll {
        display: inline-flex;
        flex-direction: row;
        width: 100%;
        gap: 61px;
        justify-content: flex-start;
    }


    .mainFormCol2 {
        display: inline-flex;
        /*flex-direction: row;*/
        justify-content: space-between;
        width: 100%;
        gap: 19px;
    }

    .mainFormCont .visa_inputField{
        width: 100%;
    }

    .mainFormCont{
        /*width: 380px;*/
        /*background: transparent;*/
    }

    .mainFormCol1{
        width: 100%;
        gap: 19px;
        align-items: stretch;
        display: inline-flex;
        /*flex-direction: row;*/
    }

    .bookinsummNdflightItinerary {
        display: inline-flex;
        flex-direction: row-reverse;
    }

    .bookinsummNdflightItinerary {
        display: inline-flex;
        gap: 15px;
        flex-direction: row-reverse;
        justify-content: center;
        position: relative;
        align-items: flex-start;
    }

    .progressTracker{
        width: 561px;
    }

    .greenNotice{
        padding: 9.28px 220.4px 9.28px 11.6px;
        margin-bottom: 13px;
        font-size: 10px;
    }

    .yourTrip{
        display: inline;
        color: #234;
        font-family: Rubik;
        font-size: 13.92px;
        font-style: normal;
        font-weight: 500;
        line-height: 16.704px;
    }

    .yourTripcont{
        display: flex;

    }

    .idontKnow{
        /*display: inline-flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-start;*/
    }

    .headingItine{
        display: block;
        padding: 8.7px 141.721px 7.644px 9.279px;
        border-radius: 1.74px 1.74px 0px 0px;
        border-bottom: 0.58px solid #DDEAF0;
        margin: 0 13px;
    }

    .itineSText{
        color:  #2C313A;
        font-family: Rubik;
        font-size: 10.44px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.528px;
    }

    .description {
        color: #4F5E71;
        text-align: center;
        font-family: Rubik;
        font-size: 10px;
        width: 67%;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 12.6px;
    }

    .termsCond .visa_next, .termsCond .visaCent {
        width: 95.4px;
        height: 33.6px;
        /*font-size: 16px;*/
    }

    .clickbag{
        padding: 13.901px 31.596px 14.499px 14.404px;
    }


    .InsList {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 17px;
        display: inline-flex;
        flex-direction: column;
        gap: 10px;
        padding-right: 10px;
        margin-bottom: 18px;
        /*width:70%;*/
    }

    .insuranceRadio1{
        width: 61%;
    }
}


@media (min-width: 1024px) {

    .makesticky{
        position: sticky;
        top: 50px;
        /*height: 80vh;*/
    }

    .checkboxxBag label {
        padding-left: 42px;}

    .mobile{
        display: none;
    }

    .tab{
        display: none;
    }

    .desktop{
        display: block;
    }

    .flightItineraryContainer {
        width: 700px;

    }

    .garantt{
        margin-bottom: 10px;
        margin-top: 20px;
        width: 90%;
    }


    .garaFormLab {
        display: inline-flex;
        flex-direction: row;
        width: 100%;
        gap: 34px;
    }

    .garaTextCont{
        display: inline-flex;
        flex-direction: column;
    }


    .HeaderGaratext{
        color: #000;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 10px;
    }
    .grantPrice{
        color: #0172CB;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 120% */
    }


    .garantFormCont{
        border-radius: 8px;
        padding: 23.5px 24px 24.5px 24px;
        border: 2px solid  #C0C8D4;
        background: #FAFCFF;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);

        margin-bottom: 20px;
    }

    .Garatext{
        color: #539C57;
        padding-left: 0;
        margin-bottom: 0;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .coRed{
        color: #FC4E52;

        /* Body 2 */
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .GarantForm {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 20px;

    }

    .liTextIn {
        color: #2E3033;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .insuranceRadio1 {
        width: 74%;
    }

    .labheadercont img {
        width: 20px;
        height: 20px;
    }

    .insPrice {
        color: #2E3033;
        font-family: Segoe UI;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .TravHeader {
        color: #252A31;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .InsList img {
        width: 10px;
        height: 10px;
        margin-right: 13px;
    }

    .InsList {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 17px;
        display: inline-flex;
        flex-direction: column;
        gap: 10px;
        padding-right: 10px;
        margin-bottom: 18px;
        width:70%;
    }
    .radioReg {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        width: 66%;
        align-items: flex-start;
    }

    .InsuranceHeader{
        color: #2E3033;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 29px;
    }

    .subTextImg{
        color: #2C313A;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .tesst .greenNotice{
        padding: 16px 568px 16px 20px;
    }
    .CarryBaggage{
        width: 100%;
    }

    .termsCond{
        width: 100%;
    }

    .moreInfoContainer2{
        /*width: 90%;*/
    }
    /*.flightItineraryContainer{*/
    /*    width: 65%;*/
    /*}*/

    .pheww{
        /*width: 100%;*/
    }

    .blueNotice{
        /*width: 90%;*/
    }

    .whoIsTrav{
        /*width: 90%;*/
    }

    .redNotice2{
        width: 100%;
    }

    .confirm{
        width: 100%;
    }

    .mainForm3{
        flex-direction: row;
    }


    .mainFormCont{
        width: 100%;
    }

    .flightCustomisation {
        width: 575px;
    }

    .greenNotice{
        padding: 16px 380px 16px 20px;
    }

    .maintext{
        font-size: 12px;
    }

    .progressTracker {
        width: 700px;
    }

    .progressTrackerCont{
        margin-bottom: 16px;
    }

    .yourTrip{
        margin-top: 24px;
        font-size: 24px;
    }

    .idontKnow{
        margin-bottom: 27px;
    }

    .itineSText{
        font-size: 18px;
    }

    .blueNoticeText {
    font-size: 14px;
    }

    .priCont {
        gap: unset;
        margin-left: 16px;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .priContBtn .mainBtn {
        padding: 11px 6px 11px 6px;
    }

    .formContainer {
        margin: 8px;
        /*display: grid;*/
        /*align-items: center;*/
        /*!*width: 750px;*!*/
        /*justify-items: flex-start;*/
    }

    .mainFormCol1, .mainFormCol2{
        margin-bottom: 10px;
        flex-direction: row;
    }

    .mainFormCol2 {
        gap: 28px;
    }

    .redNotice2Text{
        font-size: 14px;
    }

    .passDetailsText{
        font-size: 16px;
    }

    .oga_o {
        /* padding: 40px; */
        /*display: flex;*/
        /*justify-items: center;*/
        /*justify-content: center;*/
        /*width: 1024px;*/
    }

    .colAll .visa_input {
        width: 198px;
    }

    .colAll .css-1fbj4ed-control {
        font-size: 14px;
        height: 41px;
        width: 198px;
    }

    .mainFormCol2 .css-1fbj4ed-control {
        font-size: 14px;
        height: 41px;
        width: 150px;
    }

    .whereContText {
        font-size: 12px;
    }

    .checkboxw label {
        font-size: 14px;
    }

    .whereText{
        font-size: 18px;
    }

    .whoIsTravText{
        font-size: 18px;
    }

    .checkboxw{
        margin: 24px 0;
    }

    .CarryBaggageText{
        font-size: 18px;
        margin-bottom: 9px;
    }

    .CarryBaggageSubText{
        font-size: 15px;
        line-height: 20px;
    }

    .presItm{
        font-size: 20px;
    }

    .description{
        font-size: 16px;
        line-height: 16px;
    }

    .priceBagMain{
        font-size: 20px;
    }
    .checkedBag{
        font-size: 15px;
    }

    .dimen{
        font-size: 12px;
    }

    .bookMorePassText{
        font-size: 18px;
    }

    .bookMorePassBtn{
        font-size: 15px;
        padding: 14.5px 26.38px 14.5px 26px;
    }

    .termsCondCont, .termsSpan{
        font-size: 14px;
        line-height: 21px;

    }

    .termsCond .visa_next, .termsCond .visaCent {
        width: 159px;
        height: 56px;
        font-size: 16px;
    }

    .clickbag {
        padding: 23.5px 24px 24.5px 24px;
    }

    .contBtn .visa_next{
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }


}