.loginBack{
    min-height: 720px;
    height: 100vh;
    background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1679098091/Log-in_page_gvp91z.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    display: flex;
    padding: 1%;
}
.loginBack .forget{
    font-style: normal;
    font-weight: 500;
    font-size: clamp(8px, 2vw, 12px);
    line-height: 10px;
    color: #8F9AAC;
    float: right;
    margin-right: 12%;
    margin-top: 11px;
}
/* Hide the default checkbox */
.custom-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    height: 17px;
    width: 17px;
    border: 1.04847px solid #8F9AAC;
    border-radius: 2.79592px;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox:checked ~ .checkmark {
    /*background-color: #2196F3;*/
}
/* Create the checkmark */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark */
.checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.loginBack label{
    float: left;
    margin-left: 12%;
    margin-top: 24px;
    display: inline-flex;
    align-items: center;
}
.loginBack .logged{
    margin-left: 26px;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(10px, 2vw, 14px);
    line-height: 11px;
    color: #E4E7EB;
}
.loginBack .toper{
    margin-top: 30px;
}
.SpinnerContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.Spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: #3498db;
    width: 25px;
    height: 25px;
    animation: spin 1s infinite linear;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media (min-width: 760px) {
    .loginBack{
        background-image: url(https://res.cloudinary.com/dk80ynx1w/image/upload/v1681864992/Log-in_page_1_xjvrzx.svg);
        padding: 23%;
        min-height: 950px;
    }
}

@media (min-width: 1024px){
    .loginBack{
        padding: 18% 8% 0 42%;
        min-height: 1075px;
    }
    .loginBack label{
        float: unset;
        margin: auto;
        margin-top: 24px;
        display: flex;
        align-items: center;
        width: 392px;
    }
    .loginBack .forget{
        float: unset;
        margin: auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 392px;
        margin-top: 11px;
    }
}

@media (min-width: 1400px) {
    .loginBack {
        min-height: 1255px;
        max-width: 1440px;
        padding-left: unset;
        padding-right: unset;
        margin: auto;
    }
}

