.blurred-div{
    position: absolute;
    width: 360px;
    height: 85%;
    top: 50%;
    left: 50%;
    display: inline-flex;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    overflow-x: auto;
    flex-direction: column;
    align-items: stretch;
}
.IELTS1 #widthOurservices{
    width: 100%;
}

.blueHeader{
    color: rgb(255, 255, 255);
    font-family: Rubik;
    font-size: 11.95px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    padding: 5px;
    background: rgb(5, 77, 171);
}

.subBlueHeader{
    /* Body 2 */
    color: rgb(255, 255, 255);
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    width:130px;
    /* Primary/600 */
    background: rgb(5, 77, 171);
    border-radius: 4px;
    padding: 4px;
}

.IELTSInner{
    margin-top: 17px;
    display: inline-flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}


.IELTS1{
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 0 10px 10px 10px;
}

.IELTS2{
    padding: 30px 10px 10px 10px;
}



.IELTS1 .IELTSInner #inputTop{
    margin-top: 0;
}

.IELTSInner2{
    display: inline-flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.blurred-background .visa_casing{
    margin-bottom: 0;
}

.centerBtn{
    display: inline-flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 765px) {

    .blurred-div{
        width: 550px
    }

    .blurred-background .visa_input{
        width:460px;
    }

    .blurred-background .IELTSInner2 .visa_input{
        width:229px;
    }

    .blurred-background #secondInput{
        width:250px;
    }

    .IELTS1{
        display: inline-flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        padding: 0 24px 24px 24px;
    }

    .IELTS2{
        padding: 30px 10px 10px 10px;
    }

    .blurred-background .visa_casing{
        margin-bottom: 0;
    }
}

@media (min-width: 1024px) {
    .blurred-div{
        width: 830px
    }

    .blurred-background .visa_input{
        width:740px;
    }
    .blurred-background .visa_Double{
        width:500px;
    }
    .blurred-background #secondInput{
        width:500px;
    }

    .blurred-background .IELTSInner2 .visa_input{
        width:370px;
    }

    .blurred-background .visa_casing{
        margin-bottom: 0;
    }

    .blurred-background .visa_next {
              width: 197px;
              height: 56px;
              font-size: 16px;
    }

}

