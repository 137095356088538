@media (min-width: 300px) {
    .dropdown{
       right: 4px;
    }

    .seatRemaining{
        display: none;
    }

    .dropdown img{
        filter: invert(100%) grayscale(100%);
        width: 20px;
    }

    .inputEstdropdown{
        border-radius: 8px;
        border: 1px solid #E4E4E4;
        background: #FFF;
        width: 186px;
        height: 40px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }

    .inputEstdropdownCont{
        position: relative;
    }

    .multicityDropDown{
        box-shadow: 0 0.5px 4px rgba(0, 0, 0, 0.12), 0 6px 13px rgba(0, 0, 0, 0.12);
        position: absolute;
        border-radius: 8px;
        background-color: #FAFCFF;
        width: 164px;
        z-index: 10;
    }

    .stopDest{
        color:  #2C313A;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px
    }

    .multicityStop{
        text-align: left;
    }

    .multicityDropDown li:hover{
        background-color: #82A6D5;
        color: #054DAB;
    }

    .multicityDropDown li:first-child:hover{
        border-radius: 8px 8px 0 0;
    }

    .multicityDropDown li:last-child:hover{
        border-radius: 0 0 8px 8px ;
    }

    .multicityDropDown ul{
        list-style: none;
        padding: 0;
        margin-bottom: 0;
    }

    .multicityDropDown ul > :nth-child(-n+3) {
        border-bottom: rgba(52, 52, 52, 0.63) 1px solid;
    }

    .multicityDropDown li{
        padding: 8px 16px;
        color: #333;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .inputEstdropdown input{
        width: 150px;
        border: none;
        color:  #2C313A;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }


    .moreInfoCont {
        display: grid;
        width: 100%;
        position: relative;
        top:85px;
        height: 100%;
        justify-items: center;
        justify-content: center;
    }

    hr.hrline{
        display: unset;
        height: 1px;
        border: none;
        background: #E8EDF1;
        width: 65%;
    }



    .nightCont{
        width: 200%;
    }

    .nightCalc{
        display: inline-flex;
        margin: 20px 0;
        width: 100%;
        font-size: 13px;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }

    .unavilableContainer{
        display: grid;
    }
    .more-info-Booking{
        /*box-shadow: #0d6efd -1px -1px 20px 0;*/
        border-radius: 8px;
        background: #FFF;
        padding-top: 20px;
        padding-bottom: 20px;
        float: right;
        position: absolute;
        z-index: 1000;
        width: 315px;
        padding-right: 13px;
        padding-left: 13px;
        /*top: 81px;*/
        max-height: 552px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .more-info-Booking .itenery {
        padding: 30px 112px 20px 22px;
        align-items: center;
        border-radius: 3px 3px 0 0;
        border-top: 1px solid #E8EDF1;
        border-right: 1px solid #E8EDF1;
        border-left: 1px solid #E8EDF1;
        background: #FFF;
        color: #234;
        font-family: Rubik, serif;
        font-size: 18px;
        white-space: nowrap;
        font-style: normal;
        font-weight: 500;
        line-height: 21.6px;
        margin-top: 56px;
        margin-bottom: 3px;
    }
    .more-info-Booking .transport{
        color: #252A31;
        font-family: Rubik, serif;
        font-size: 15px;
        white-space: nowrap;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-left: 18px;
        margin-bottom: 30px;
    }
    .more-info-Booking .stops{
        display: inline-flex;
        gap: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 16px;
        padding-left: 15px;
        border: 1px solid #F2F7FE;
        background: #FFF;
        width: 100%;
        align-items: center;
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.10);

    }
    .more-info-Booking .stops .arrow{
        height: 123px;
    }
    .more-info-Booking .transport span{
        color: #4F5E71;
        font-family: Rubik, serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-left: 15px;
    }
    .more-info-Booking .transport span span{
        color: #4F5E71;
        font-family: Rubik, serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin-left: 0px;
    }
    .moreInfoImg{
        height: 16px;
        float: right;
    }
    .more-info-Booking .numb{
        color: #252A31;
        font-family: Rubik, serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }
    .more-info-Booking .tDiff{
        width: 120%;
        color: #252A31;
        font-family: Rubik, serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }
    .more-info-Booking .airway{
        border-radius: 12px;
        background: #F5F7F9;
        padding-right: 10px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        width: 170px;
    }
    .more-info-Booking .airway img{
        width: 30px;
        height: 30px;
        border-radius: 200px;
        float: left;
    }
    .more-info-Booking .timeDetail{
        display: inline-flex;
        flex-direction: column;
        gap: 15px;
    }
    .more-info-Booking .layover{
        display: inline-flex;
        gap: 8px;
        color: #4F5E71;
        font-family: Rubik, serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        align-items: center;
        padding-top: 12px;
        padding-left: 14px;
    }
    .more-info-Booking .layover img {
        width: 16px;
        height: 16px;
    }
    .more-info-Booking .numb span{
        color: #4F5E71;
        text-align: right;
        font-family: Rubik, serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
    .unavailable {
        text-align: center;
        margin: 30px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }

    .unavailable img{
        width: 208.883px;
        height: 155.643px;
    }

    .unavailbleText{
        padding: 23px 0;
        width: 265px;
        /*justify-content: center;*/
        color: #000;
        text-align: center;
        font-size: 16px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .unavilSubText{
        /* Subtitle 1 */
        color: rgb(0, 0, 0);
        font-family: Rubik;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: center;
    }

    .unavailableBtn{
        display: inline-flex;
        width: 231px;
        height: 40px;
        padding: 8.334px 16.669px 8.334px 15.627px;
        justify-content: center;
        align-items: center;
        border-radius: 4.167px;
        background: #054DAB;
        color: #FFFFFF;
        cursor: pointer;
    }

    #phil{
        margin-right: 19px;
    }

    .myWidth{
        max-height: 1561px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .ticketButtonCase{
        width: 100%;
        text-align: center;
    }
    .ticketLoad{
        width: 322px;
        height: 40px;
        border-radius: 3px;
        background: #CBE8CD;
        color: #252A31;
        text-align: center;
        font-size: 16px;
        margin-top: 50px;
        border: 0;
    }
    .timeSpent{
        padding: 6px 11.55px 6px 9px;
        border-radius: 12px;
        border: 1px solid #DEF0FC;
        background: #F1F8FE;
        width: 70px;
        color: #005AA3;
        font-size: 11px;
        line-height: 12px;
    }
    .airImg{
        width: 24px;
        height: 24px;
        border-radius: 200px;
    }
    .flightBox{
        display: inline-flex;
        gap: 3px;
        align-items: center;
    }
    .noOfStops{
        border-radius: 12px;
        border: 1px solid #E8EDF1;
        background: #F5F7F9;
        width: 63px;
        color: #252A31;
        font-size: 12px;
        line-height: 12px;
        padding: 6px 8.94px 6px 9px;
    }
    .fromTo{
        width: 16px;
        height: 79.18px;
    }
    .fromLocation{
        color: #2E353B;
        font-size: 14px;
        line-height: 19.6px;
    }
    #fromLocation{
        margin-bottom: 9px;
    }
    .flightDate{
        color: #4F5E71;
        font-size: 14px;
        line-height: 20px;
        margin-left: 6px;
        padding-left: 9.28px;
    }
    .flightCover{
        border-bottom: 1px solid #E8EDF1;
    }

    .actualDetails{
        display: inline-flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        padding: 12px 9.28px;
    }
    .detailCover{
        border-top: 1px solid #E8EDF1;
        border-bottom: 1px solid #E8EDF1;
        padding-top: 8px;
    }
    .fromLocation span {
        color: #697D95;
    }
    .flightLocation{
        color: #4D5055;
        font-size: 9px;
        line-height: 16.2px;
        margin-left: 20px;
        margin-top: 4px;
        margin-bottom: 9px;
    }
    .flightLocation span{
        color: #020812;
    }
    .flightHeaders{
        display: inline-flex;
        justify-content: space-around;
        width: 100%;
        padding-left: 21px;
        padding-top: 10px;
        padding-bottom: 10px;
        /*background: var(--white-100, #FAFCFF);*/
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    }
    .flightOutline{
        border-bottom: 1.368px solid #0172CB;
        color: #0172CB;
        font-size: 10px;
        line-height: 10.941px;
        padding: 5.471px 7.294px;
    }
    #flightFilter{
        color: #0172CB;
    }
    .flightHeaders .item{
        color: #252A31;
        font-size: 10px;
        line-height: 10.941px;
        padding: 5.471px 7.294px;
    }
    .flightHeaders .item span{
        color: #4F5E71;
        font-size: 7px;
    }
    .flightOutline span{
        color: #4F5E71;
        font-size: 7px;
    }
    .modifyToggle{
        display: flex;
        justify-content: center;
        background: #FAFCFF;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        padding-top: 31px;
        padding-bottom: 31px;
        width: 100%;
    }
    .sub-fix{
        /*width: 100%;*/
    }
    .modifyToggle .fir{
        max-width: 370px;
        width: 100%;
    }
    .Modify{
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .modifySave{
        width: 100%;
        max-width: 390px;
    }
    #alignRight{
        text-align: right;
    }
    #alignLeft{
        text-align: left;
    }
    .searchHeader{
        display: inline-flex;
        width: 100%;
        margin-top: 48px;
        background: #021A39;
        padding-right: 27.5px;
        padding-left: 27px;
        padding-bottom: 4px;
        padding-top: 5px;
        gap: 7%;
        justify-content: center;
        align-items: center;
    }
    .searchAirports{
        display: inline-flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.4px;
        color: #FAFCFF;
        margin-bottom: 8px;
    }
    .otherDetails{
        display: inline-flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 7px;
        line-height: 8px;
        justify-content: center;
        color: #FAFCFF;
        height: 28px;
    }
    .otherDetails span{
        display: block;
        width: 68px;
        height: 100%;
        border-right: 1px solid white;
        padding-top: 6px;
    }
    .searchHeader button{
        width: 58px;
        height: 22px;
        border: 0.5px solid  #054DAB;
        border-radius: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: 9px;
        color: #FAFCFF;
        background: transparent;
    }
    .searchAirports div{
        width: 113px;
        text-align: center;
    }
    .searchDetails{
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .filterHead{
        width: 100%;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #020812;
        margin-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
    }
    .filterHead span{
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #054DAB;
        float: right;
    }
    .filterCase{
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 279px;
        background: #FAFCFF;
        box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
        border-radius: 3px;
        padding-top: 17px;
    }
    .slider-container {
        /*width: 80%;*/
        /*margin: 0 auto;*/
    }

    .rc-slider {
        /*position: relative;*/
        /*height: 6px;*/
        border-radius: 3px;
        background-color: white;
    }

    .rc-slider-rail {
        /*position: absolute;*/
        /*width: 100%;*/
        /*height: 6px;*/
        border-radius: 3px;
        background-color: #e9e9e9;
    }

    .rc-slider-track {
        /*position: absolute;*/
        /*height: 6px;*/
        border-radius: 3px;
        background-color: #054DAB;
    }

    .rc-slider-handle {
        background-color: #FFFFFF;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
    }

    .rc-slider-handle:active {
        background-color: #003e80;
    }
    .filterCase .enclose{
        width: 248px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    .myHr{
        width: 100%;
        height: 2px;
        background: #ABADB0;
        margin-top: 15px;
    }
    .enclose .first{
        width: 100%;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 8px;
        color: #054DAB;
        margin-bottom: 10px;
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 15px;
    }
    #filter_checkmark:after {
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
    }
    .filter-checkbox:checked ~ .checkmark {
        background-color: #0a58ca;
    }
    .topper span{
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #054DAB;
        float: right;
    }
    .enclose .sec{
        width: 100%;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 8px;
        color: #054DAB;
        margin-bottom: 6px;
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 15px;

    }
    .enclose .sec span{
        display: block;
        font-style: normal;
        font-size: 12px;
        line-height: 9px;
        float: right;
    }
    .enclose .topper{
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ABADB0;
        margin-bottom: 19px;
    }
    .filterCheckboxer{
        margin-top: 19px;
        margin-bottom: 24px;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100px;
        position: relative;
    }
    .bags{
        display: inline-flex;
        /*gap: 3px;*/
        width: 100%;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #020812;
        align-items: center;
        justify-content: space-between;
    }
    .bags img{
        width: 18px;
    }
    .bags button{
        display: flex;
        width: 31.86px;
        height: 20px;
        background: #ACC4E3;
        border-radius: 24.9446px;
        border: 0;
        align-items: center;
        color: white;
        justify-content: center;
    }
    .bags .pink{
        font-weight: 500;
        font-size: 7.98228px;
        line-height: 12px;
        text-align: center;
        color: #12A3D8;
    }
    #bagButton{
        background-color: #054DAB;
    }
    .airSearch{
        display: inline-flex;
        gap: 7px;
        padding-right: 22px;
        padding-left: 22px;
        padding-top: 6px;
        padding-bottom: 6px;
        /*margin-bottom: 23px;*/
        background: #EEF5FE;
        border-radius: 4.5px;
    }
    .airSearch img{
        width: 13px;
        height: 13px;
    }
    .airSearch input{
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;
        color: #032756;
        border: 0;
        background: transparent;
    }
    .availableFlight{
        display: none;
    }
    .filterCover{
        position: absolute;
        margin-top: 84px;
        margin-bottom: 87px;
    }
    .ticketCase{
        border-top: 1px solid #E8EDF1;
    }
    .ticketPricing{
        display: inline-flex;
        justify-content: space-between;
        padding: 12px;
        width: 100%;
    }
    .ticketPricing .price{
        color: #054DAB;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 28.8px;
    }
    .ticketPricing button{
        display: flex;
        width: 150px;
        height: 40px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 5px;
        background: #054DAB;
        color: #F0F6FE;
        font-size: 16px;
        border: 0;
    }
    .flightDetails{
        margin-top: 12px;
    }
    .moreDetails{
        display: inline-flex;
        gap: 3px;
        color: #252A31;
        font-size: 12px;
        padding: 4px 13.44px 4px 9px;
        align-items: center;
        line-height: 12px;
        border-radius: 12px;
        border: 1px solid #E8EDF1;
        background: #F5F7F9;
        margin-top: 13px;
        margin-left: 9.28px;
        margin-bottom: 9.28px;

    }
    .moreDetails img{
        width: 16px;
        height: 16px;
        flex-shrink: 0;
    }

}

@media (min-width: 375px){
    .more-info-Booking{
        width: 341px;
    }
}

@media (min-width: 760px) {

    .seatRemaining{
        display: block;
        font-size: 9px;
    }
    .makeBoldTic{
        font-weight: 600;
    }

    .filterCase{width: 300px}

    .unavailable {
        text-align: center;
        margin: 30px 0;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }

    .unavailableBtn {
        display: inline-flex;
        width: 241px;
        height: 49px;
        padding: 8.334px 16.669px 8.334px 15.627px;
        justify-content: center;
        align-items: center;
        border-radius: 4.167px;
        background: #054DAB;
        color: #FFFFFF;
        cursor: pointer;
    }

    .unavailbleText {
        padding: 42px 0;
        width: 391px;
        /* justify-content: center; */
        color: #000;
        text-align: center;
        font-size: 25px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .unavailable img {
        width: 300.883px;
        height: 200px;
    }

    .unavailbleText {
        padding: 26px 0;
        font-size: 23px;
        width: 401px;
    }

    .unavailableBtn {
        display: inline-flex;
        width: 296px;
        height: 65px;
        padding: 8.334px 16.669px 8.334px 15.627px;
        justify-content: center;
        align-items: center;
        border-radius: 4.167px;
        background: #054DAB;
        color: #FFFFFF;
        cursor: pointer;
    }

    .filterHead{
        /*color: var(--black-600, #020812);*/
        font-size: 20px;
        /*font-family: Rubik;*/
        /*font-style: normal;*/
        /*font-weight: 400;*/
        /*line-height: 12.528px;*/
    }

    .filterHead span{
        /*color: var(--primary-600, #054DAB);*/
        font-size: 14px;
        /*font-family: Rubik;*/
        /*font-style: normal;*/
        /*font-weight: 400;*/
        /*line-height: 10.44px;*/
    }

    .enclose .topper{
        font-size: 15px;
        margin-top: 10px;
    }

    .topper span{
        font-size: 13px;
    }

    .enclose .first{
        font-size: 13px;
        margin-bottom: 20px;
    }

    .enclose .sec{
        font-size: 13px;
        padding-top: 20px;
    }

    .enclose .sec span{
        font-size: 13px;
    }

    #visa_tick{
        font-size: 14px;
    }

    .bags .pink{
        padding: 0 5px;
        font-size: 10px;
    }

    .ticketLoad{
        width: 425px;
        height: 28px;
        border-radius: 1.74px;
        font-size: 9.28px;
        margin-top: 12px;
    }
    .fromTo {
        width: 9px;
        height: 46px;
    }
    .moreDetails{
        padding: 2.32px 7.795px 2.32px 5.22px;
        font-size: 6.96px;
    }
    .moreDetails img{
        width: 9.28px;
        height: 9.28px;
    }
    .flightDate{
        font-size: 8.12px;
    }
    #fromLocation {
        margin-bottom: 4px;
    }
    .airImg{
        width: 13px;
        height: 13px;
    }
    .noOfStops{
        width: 37px;
        border-radius: 6.96px;
        padding: 1.48px 4.284px 3.48px 5.22px;
        font-size: 6.96px;
        height: 16px;
    }

    .timeSpent{
        width: 46px;
        font-size: 6.96px;
        padding: 3.48px 5.063px 3.48px 8.22px;
        border-radius: 10.96px;
        height: 19px;
    }
    .fromLocation{
        font-size: 8.12px;
    }
    .ticketPricing{
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;
        gap: 21px;
    }
    .ticketPricing button{
        width: 113.1px;
        height: 23.2px;
        font-size: 9.28px;
    }
    .ticketPricing .price{
        font-size: 13.92px;
    }
    .ticketCase{
        margin-top: 12px;
        border-right: 1px solid #E8EDF1;
    }
    .flightDetails{
        border: 1px solid #E8EDF1;
        width: 100%;
    }
    .flightCover{
        width: 425px;
        display: inline-flex;
        justify-content: space-between;
    }
    .flightTicket{
        display: inline-flex;
        align-items: center;
    }
    .filterSeparator{
        display: flex;
        gap: 29px;
        justify-content: center;
    }

    .airSearch input {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: #032756;
        border: 0;
        background: transparent;
    }

    .filterCover{
        position: unset;
        margin-top: 24px;
    }
    .availableFlight{
        display: unset;
        color: #000204;
        text-align: center;
        font-size: 21.92px;
        font-weight: 700;
        line-height: 43px;
        padding-bottom: 0px;
    }
    .flightLocation{
        font-size: 10.44px;
        margin-bottom: 15px;
        margin-top: 19px;
    }
    #flightFilter{
        display: none;
    }
    .modifyToggle .fir{
        max-width: unset;
        width: unset;
    }
    .modifySave{
        max-width: unset;
    }
    .searchDetails{
        display: unset;
        flex-direction: unset;
    }
    .searchHeader{
        padding-left: 118px;
        padding-bottom: unset;
        padding-top: unset;
        display: inline-flex;
        gap: 50px;
        justify-content: unset;
    }
    .searchAirports div {
         width: unset;
    }
    .searchAirports{
        font-style: normal;
        font-weight: 400;
        font-size: 8.12px;
        line-height: 10px;
        margin-right: 48px;
    }
    .otherDetails span{
        text-align: center;
        border-right: 1px solid white;
        border-left: 1px solid white;
    }
    .flightHeaders{
        width: 433px;
    }
    .myWidth{
        width: 433px;
    }

    .flightHeaders .item span{
        font-size: 8.12px;
    }


}



@media (min-width: 1024px){
    .seatRemaining{
        font-size: 12px;
    }

    .more-info-Booking .layover img {
        width: 24px;
        height: 24px;
    }

    .more-info .transport{
        font-size: 16px;
    }

    .more-info-Booking {
        width: 532px;
        padding-top: 35px;
        padding-right: 45px;
        padding-bottom: 35px;
        padding-left: 45px;
    }

    .more-info-Booking .itenery {
        padding: 35px 112px 25px 22px;
    }

    .unavailbleText {
        padding: 26px 0;
        font-size: 32px;
        width: 516px;
    }

    .unavailable img {
        width: 401px;
        height: 298.794px;
    }

    .filterSeparator {
        display: flex;
        gap: 19px;
        justify-content: center;
    }

    .enclose .topper {
        font-size: 18px;
        margin-top: 12px;
    }

    .enclose .first {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .enclose .sec {
        font-size: 14px;
        padding-top: 22px;
    }

    .enclose .sec span {
        font-size: 14px;
    }

    #visa_tick {
        font-size: 16px;
    }

    .bags .pink {
        padding: 0 5px;
        font-size: 12px;
    }

    .airSearch input {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 11px;
        color: #032756;
        border: 0;
        background: transparent;
    }

    .bags {
        display: inline-flex;
        width: 110%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #020812;
        align-items: center;
        justify-content: space-between;
    }

    .availableFlight{
        font-size: 27px;
        line-height: 50px;
    }
    .flightLocation{
        font-size: 18px;
    }
    .ticketLoad{
        width: 692px;
        height: 49px;
        border-radius: 3px;
        font-size: 16px;
        margin-top: 25px;
    }
    .myWidth{
        width: 692px;
    }
    .ticketPricing .price{
        font-size: 24px;
    }
    .ticketPricing button{
        width: 195px;
        height: 40px;
        font-size: 16px;
    }
    .moreDetails{
        padding: 4px 13.44px 4px 9px;
        font-size: 12px;
    }
    .moreDetails img{
        width: 16px;
        height: 16px;
    }
    .flightDate {
        font-size: 14px;
    }
    #fromLocation{
        margin-bottom: 8px;
    }
    .noOfStops {
        width: 62px;
        border-radius: 12px;
        padding: 6px 9.11px 6px 9px;
        font-size: 12px;
        height: 23px;
    }
    .airImg{
        width: 24px;
        height: 24px;
    }
    .timeSpent {
        width: 71px;
        font-size: 12px;
        padding: 6px 8.73px 6px 9px;
        border-radius: 12px;
        height: 24px;
    }
    .fromLocation{
        font-size: 14px;
    }
    .fromTo {
        width: 16px;
        height: 79px;
    }
    .flightCover{
        width: 692px;
    }
    .flightHeaders{
        width: 692px;
    }
    .flightHeaders .item{
        font-size: 16px;
    }
    .flightOutline{
        font-size: 16px;
    }
    .flightHeaders .item span{
        font-size: 14px;
    }
    .flightOutline span{
        font-size: 14px;
        line-height: 13.941px;
    }
    .searchHeader{
        margin-top: 55px;
        justify-content: center;
        padding-left: unset;
    }
    .searchAirports{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
    .otherDetails{
        font-size: 14px;
        height: 51px;
        line-height: 16px;
    }
    .otherDetails span{
        width: 115px;
        padding-top: 9px;
    }
    .searchHeader button{
        width: 105px;
        height: 44px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-top: 7px;
        margin-bottom: 7px;
    }

}
