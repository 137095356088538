.parent {
    display: grid;
    place-items: center;  /* Centers both horizontally and vertically */
    height: 100vh;        /* Adjust height as needed */
}

.child {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.white-icon {
    filter: invert(100%) brightness(10);
    width: 20px;
}

@media (min-width: 320px){
    .errimg{
      width: 272px;
    }
    .parent {
        display: grid;
        place-items: center;  /* Centers both horizontally and vertically */
        height: 100vh;        /* Adjust height as needed */
    }

    .white-icon {
        filter: invert(100%) brightness(10);
        width: 20px;
        margin-right: 10px;
    }

    .child h1{
        font-weight: bold;
        text-align: center;
        font-size: 25px;
        color: #000000;
    }

    .child p{
        text-align: center;
        font-size: 19px;
        color: #4B4B4B;
    }

    .child .unavailableBtn{
        font-size: 10px;
        /*margin-right: 10px;*/
    }
}

@media (min-width: 768px){
    .errimg{
        width: 394px;
    }


    .child h1{
        font-weight: bold;
        text-align: center;
        font-size: 40px;
        color: #000000;
    }

    .child p{
        text-align: center;
        font-size: 30px;
        width: 72%;
        color: #4B4B4B;
    }

    .child .unavailableBtn{
        font-size: 15px;
        /*margin-right: 10px;*/
    }

}