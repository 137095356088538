.oursercont{
    padding-top: 40px;
    margin: 21px;
    padding-bottom: 60px;
    display: grid;
}

.blurred-background {
    position: fixed;
    margin-top: 22px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 999; /* Ensure it's on top */
}




.makrReadBold{
    cursor: pointer;
    color: #FFF;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.servBg:hover,.servBg2:hover,.servBg3:hover,.servBg4:hover,.servBg5:hover, .servBg6:hover,.servBg7:hover,.servBg8:hover,.servBg9:hover,.servBg10:hover,.servBg11:hover{
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px #054DAB;
}

.buttonServ{
    border-radius: 4.64px;
    border: 0.58px solid #CDDBEE;
    color: #CDDBEE;
    display: inline-flex;
    padding: 9.28px 18.56px 9.28px 17.4px;
    justify-content: center;
    align-items: center;
    gap: 5.8px;
    background: transparent;
    cursor: pointer;
    margin-top: 20px;
}

.btnTextos{
    color: #FAFCFF;
    font-family: Rubik;
    font-size: 8.12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.725px;
}

#makeRight{
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    text-align: end;
}

.ourserheadercont{
    text-align: center;
    display: inline-flex;
    flex-direction: column;
}

.ourserheader{
    color:  #020812;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 9px;
}

.ourserheadertext{
    color: #2C313A;
    text-align: center;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.servBg{
    background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715957314/JaromTravels/kyw9gehiijl0wbahectg.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
}

.servBg2{
    background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715957537/JaromTravels/i9swhzhzzfipugidjh5f.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
}

.servBg3{
    background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958186/JaromTravels/qxzii5wn79wjp6azyetb.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
}

.servBg4{
    background-image: url(https://res.cloudinary.com/do5wu6ikf/image/upload/v1701450483/starpenzu/Frame_133547_sho6ip.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
}

.servBg5{
    background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958233/JaromTravels/akivk7sxppj95vxdisyt.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
}

.servBg6{
    background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958349/JaromTravels/ycxofpszqoxocpq7zyaq.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
}
.servBg7 {
    position: relative; /* Make the container a positioning context for the pseudo-element */
    background-image: url(https://th.bing.com/th/id/R.5147b6a983c8c0b9b212a878945bdfe3?rik=N15Pn9OVxXdurg&pid=ImgRaw&r=0);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
    overflow: hidden; /* Ensure the overlay doesn't extend beyond the container */
}

.servBg7::before {
    content: ''; /* Required to display the pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Black overlay with 50% opacity */
    z-index: 1; /* Place overlay behind the text */
}

.servBg7 * {
    position: relative;
    z-index: 2; /* Ensure text is above the overlay */
}

.servBg8{
    background-image: url(https://th.bing.com/th/id/R.49ee4107e44e22739400ecfeb93df16e?rik=wI8JWwy0Lv5IIQ&pid=ImgRaw&r=0);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
    overflow: hidden; /* Ensure the overlay doesn't extend beyond the container */
}



.servBg9{
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
    overflow: hidden;
    background-image: url(https://res.cloudinary.com/do5wu6ikf/image/upload/v1726061477/Jarom/depositphotos_42374755-stock-photo-african-american-college-students_1_pq0pqq.svg);
}


.servBg10{
    background-image: url(https://res.cloudinary.com/do5wu6ikf/image/upload/v1726061206/Jarom/OIP_1_yjkgxm.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
    overflow: hidden; /* Ensure the overlay doesn't extend beyond the container */
}

.servBg11{
    background-image: url(https://res.cloudinary.com/do5wu6ikf/image/upload/v1726061208/Jarom/R_1_imccfy.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    padding: 30px;
    text-align: left;
    border-radius: 8px;
    overflow: hidden; /* Ensure the overlay doesn't extend beyond the container */
}




.servCompcont{
    margin-top: 21px;
}

.servCompHeader{
    color: #FAFCFF;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.15px;
    margin-bottom: 16px;
}

.servCompSubText{
    color: #FFF;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (min-width: 765px) {

    /*.blurred-div{*/
    /*    width: 550px*/
    /*}*/

   .ourserheader{
       font-size: 20.3px;
       font-style: normal;
       font-weight: 700;
       line-height: normal;
       letter-spacing: 0.145px;
   }
    .btnTextos{
        color: #FAFCFF;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.725px;
    }

    .btnArros img{
        width: 24px;
        height: 24px;
    }

    .servCompcont {
        margin-top: 32.42px;
    }

    .servBg{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715957314/JaromTravels/kyw9gehiijl0wbahectg.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 30px;
        text-align: left;
        border-radius: 4.15px;
    }
    .servBg2{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715957537/JaromTravels/i9swhzhzzfipugidjh5f.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 30px;
        text-align: left;
        border-radius: 4.15px;
    }

    .servBg3{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958186/JaromTravels/qxzii5wn79wjp6azyetb.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 30px;
        text-align: left;
        border-radius: 4.15px;
    }

    .servBg4{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958233/JaromTravels/akivk7sxppj95vxdisyt.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 30px;
        text-align: left;
        border-radius: 4.15px;
    }

    .servBg5{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958233/JaromTravels/akivk7sxppj95vxdisyt.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 30px;
        text-align: left;
        border-radius: 4.15px;
    }

   .servBg6{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958349/JaromTravels/ycxofpszqoxocpq7zyaq.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 30px;
        text-align: left;
        border-radius: 4.15px;
    }

    .ourserheadertext{
        text-align: center;
        font-family: Rubik;
        font-size: 10.44px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        /*width: 303px;*/
    }

    .servCompHeader{
        font-size: 28.42px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .servCompSubText{
        font-size: 10.44px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 400px;
    }
}

@media (min-width: 1024px) {
    .makrReadBold{
        cursor: pointer;
        color: #FFF;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .ourserheader{
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.25px;
        margin-bottom: 16px;
    }

    .servBg{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715957314/JaromTravels/kyw9gehiijl0wbahectg.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 60px;
        text-align: left;
        border-radius: 10px;
    }



    .servBg2{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715957537/JaromTravels/i9swhzhzzfipugidjh5f.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 60px;
        text-align: left;
        border-radius: 10px;
    }

    .servBg3{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958186/JaromTravels/qxzii5wn79wjp6azyetb.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 60px;
        text-align: left;
        border-radius: 10px;
    }

    .servBg4{
        background-image: url(https://res.cloudinary.com/do5wu6ikf/image/upload/v1701450483/starpenzu/Frame_3088_h_hatdws.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 60px;
        text-align: left;
        border-radius: 10px;
    }

    .servBg5{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958233/JaromTravels/akivk7sxppj95vxdisyt.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 60px;
        text-align: left;
        border-radius: 10px;
    }

    .servBg6{
        background-image: url(http://res.cloudinary.com/dk80ynx1w/image/upload/v1715958349/JaromTravels/ycxofpszqoxocpq7zyaq.svg);
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        padding: 60px;
        text-align: left;
        border-radius: 10px;
        /*background-color: linear-gradient(180deg, #EB568C 0%, #ED5E76 100%);*/
    }

    .ourserheadertext{
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .servCompHeader{
        font-size: 49px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .servCompSubText{
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 700px;
    }

    .servCompcont {
        margin-top: 56px;
    }



    .oursercont {
        padding-top: 80px;
    }
}

