
@media (min-width: 300px) {
    .terms{
        display: inline-block;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: justify;
        padding-top: 60px;
        color: #000000;
        padding-right: 21px;
        padding-left: 16px;
    }
    .terms-button{
        display: block;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 157px;
        height: 40px;
        border: 1px solid #054DAB;
        border-radius: 5px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #033372;
        background: transparent;
        float: right;
        margin-top: 21px;
        margin-bottom: 21px;
    }
    .terms .group{
        margin-top: 16px;
        font-size: 12px;
        line-height: 14px;
    }
    .terms .split{
        display: inline-flex;
        gap: 3px;
        margin-top: 8px;
    }
    form{
        text-align: unset;
    }
    .myinput:invalid:required {
        border: 1px solid #FC4E52;
    }
    .visa_head {
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -1.5px;
        color: #2C313A;
        margin-top: 9px;
        padding-left: 16px;
        padding-bottom: 20px;
    }

    .visa_selection {
        margin-left: 12px;
        margin-right: 12px;
        background: #EEF5FE;
        padding-top: 20px;
        padding-bottom: 24px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Rubik', serif;
        font-style: normal;
    }

    .visa_selection .title {
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        color: #6D8494;
        margin-bottom: 9px;
    }

    .visa_selection .type {
        width: 246px;
        height: 41px;
        background: #FFFFFF;
        padding-left: 16px;
        padding-top: 11px;
        border: 1px solid #DDEAF0;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #6D8494;
        margin: auto auto 24px;
    }

    .visa_selection .duration {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #223344;
    }

    .visa_selection .duration span {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #399AF4;
    }

    .visa_timing {
        padding-left: 32px;
        margin-top: 15px;
        margin-bottom: 36px;
        width: 263px;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #6D8494;
    }

    .visa_moreInfo {
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: #223344;
        margin-right: 12px;
        background: #EEF5FE;
        margin-left: 12px;
        margin-bottom: 16px;
    }

    .visa_moreInfo span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #6D8494;
    }

    .visa_cc {
        margin-right: 12px;
        background: #CBE8CD;
        margin-left: 12px;
        margin-bottom: 43px;
        padding-top: 8px;
        padding-left: 20px;
        padding-bottom: 8px;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        color: #2C313A;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
        border-radius: 4px;
    }

    .visa_cc span {
        width: 264px;
    }

    .visa_information {
        margin-right: 12px;
        background: #F3F8FF;
        margin-left: 12px;
        margin-bottom: 8px;
        font-family: 'Rubik', serif;
        font-style: normal;
        padding-left: 8px;
        padding-top: 16px;
        padding-bottom: 7px;
    }

    .visa_information .heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #223344;
        padding-bottom: 17px;
        border-bottom: 1px solid #DEE2E6;
    }

    .visa_information .more-info {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #223344;
    }

    .visa_information .more-info div {
        margin-top: 8px;
    }

    #correct_ {
        margin-bottom: 0;
    }

    .visa_input {
        background: #FFFFFF;
        border: 1px solid #DDEAF0;
        border-radius: 4px;
        width: 100%;
        height: 41px;
        padding: 10px;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #6D8494;
    }

    .visa_Double {
        width: 100%;
        display: inline-flex;
    }

    #inputTop {
        margin-top: 17px;
    }

    #firstInput {
        width: 78px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    #secondInput {
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }

    .visa_inputField label {
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #6D8494;
        margin-bottom: 6px;
    }

    .visa_inputField label span {
        color: #F50000;
    }

    .visa_details {
        padding-left: 15px;
        padding-bottom: 23px;
        background: #F3F8FF;
        padding-right: 9px;
        margin-left: 12px;
        margin-right: 12px;
    }

    .visa_details input:focus {
        outline-offset: unset;
        border: 1px solid #DDEAF0;
    }

    .visa_progressBar {
        margin-left: 21px;
        margin-right: 21px;
        margin-top: 56px;
        background: #FFFFFF;
        padding-left: 14.5px;
        padding-right: 14.5px;
        padding-top: 5px;
        box-shadow: 0px 2.32px 4.64px rgba(0, 0, 0, 0.05);
    }

    .visa_progress {
        height: 8px;
        background: #0066FF;
        border-radius: 29px;
    }

    .visa_percent {
        font-weight: 400;
        font-size: 8px;
        line-height: 6px;
        margin-left: 3px;
        margin-top: 3px;
    }

    .visa_sizeWarning {
        width: 264px;
        height: 30.28px;
        padding: 4.64px 50.1337px 4.64px 11.6px;
        background: #CBE8CD;
        box-shadow: 0px 1.16px 2.32px rgba(0, 0, 0, 0.075);
        border-radius: 2.32px;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        color: #010F22;
        margin-top: 8px;
        margin-left: 11px;
        margin-bottom: 24px;
    }

    .visa_next {
        display: inline-flex;
        gap: 6px;
        justify-content: center;
        align-items: center;
        width: 254px;
        height: 33px;
        background: #054DAB;
        border-radius: 4.64px;
        border: 0;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 9.28px;
        line-height: 11px;
        color: #FAFCFF;

    }

    .visa_casing {
        text-align: center;
        margin: auto;
        margin-bottom: 73px;
        width: 300px;
    }

    .visaCent {
        margin-top: 7px;
    }

    /*#visaPrev {*/
    /*    transform: scaleX(-1);*/
    /*}*/
    .visa_applicant {
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
    }

    .visa_applicant span {
        color: #F50000;
    }

    #visa_rad {
        margin-right: 8px;
    }

    .visa_choice {
        margin-bottom: 8px;
    }

    #visa_topper {
        margin-top: 8px;
    }

    .visa_choice label {
        margin-left: 8px;
    }

    .visa_dull {
        color: #6D8494;
        width: 249px;
    }

    .visa_multiple {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9.28px 18.56px 9.28px 17.4px;
        gap: 5.8px;
        width: 255px;
        height: 23px;
        background: #054DAB;
        border-radius: 4.64px;
        border: 0;
        color: white;
        font-family: 'Rubik', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 9.28px;
        line-height: 11px;
        margin: 14px auto auto;
    }

    #visa_info {
        margin-top: 16px;
    }

    #visa_area {
        height: 88px;
    }

    #visa_checkmark:after {
        border: solid #160b0b;
        border-width: 0 2px 2px 0;
    }

    #visa_tick {
        margin-left: 21px;
        font-size: 12px;
        line-height: 21px;
    }

    .visa_ttick {
        font-size: 12px;
        line-height: 20px;
        color: #399AF4;
    }

    .visa_lcheck {
        display: inline-flex;
    }

    #timiButton {
        border: 1px solid #054DAB;
        background: transparent;
        color: #054DAB;
    }
    .visaTopper{
        display: block;
        visibility: unset;
    }
    .visaSidder{
        display: none;
    }
    .ssl{
        display: none;

    }
    .visaPop{
        width: 212px;
        height: 64px;
        background: #322F35;
        border-radius: 4px;
        padding: 4px 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.4px;
        color: #F5EFF7;
        float: right;
        margin-bottom: 40px;
    }


}
@media (min-width: 760px) {
    .visaDivider{
        display: inline-flex;
        width: 100%;
        gap: 22px;
        justify-content: center;
    }
    .visaTopper{
        display: none;
        visibility: hidden;
    }
    .visa_dull {
        width: unset;
    }
    .visaSidder{
        display: block;
    }
    .visaLeft{
        width: 502px;
        margin-top: 25px;
    }
    .visaRight{
        width: 195px;
        margin-top: 100px;
    }
    .visa_selection .title {
        font-size: 10px;
    }
    .visa_selection{
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 11px;
        padding-right: 11px;
        padding-top: 11px;
    }
    .visaKeeper{
        padding-left: 11px;
        padding-right: 11px;

    }
    .visa_timing {
        padding-left: 11px;
        margin-top: 15px;
        margin-bottom: 20px;
        width: 152px;
        font-size: 8px;
        color: #6D8494;
        line-height: 13px;
    }
    .visa_moreInfo {
        padding-top: 12px;
        padding-left: 12px;
        padding-bottom: 12px;
        font-size: 12px;
        margin-left: 0;
        margin-right: 0;
    }
    .visa_moreInfo span{
        font-size: 10px;
        line-height: 9px;
    }
    .visa_cc{
        margin-left: 0;
        margin-right: 22px;
        padding-top: 4px;
        padding-left: 12px;
        font-size: 8px;
        line-height: 12px;
    }
    .visa_head{
        font-size: 28px;
    }
    .visa_information .heading {
        font-size: 12px;
        padding-bottom: 9px;
        width: 502px;
    }
    .ssl{
        display: block;
        float: right;
        padding-right: 15px;
    }
    .visa_information {
        background: transparent;
    }
    .visa_information .more-info {
        padding-top: 10px;
        font-weight: 500;
    }
    .visa_details{
        background: transparent;
        margin-left: 0;
        padding-right: 0;
        margin-right: 0;
    }
    .visa_inputField label {
        font-size: 10px;
    }
    .visaFirstLast{
        display: inline-flex;
        justify-content: center;
        align-items: baseline;
        gap: 11px;
    }
    .visa_input{
        font-size: 10px;
        height: 38px;
        width: 236px;
    }
    #secondInput{
        width: 159px;
    }
    #correct_{
        margin-left: 0;
        margin-right: 0;
    }
    .visaIn{
        display: inline-flex;
        gap: 8px;
    }
    .visa_sizeWarning{
        width: 264.08px;
        height: 35.25px;
        font-size: 9.84239px;
        line-height: 15px;
        text-align: center;
        padding-top: 10px;
        margin-bottom: 33px;
    }

    #visa_sizeWarning{
        align-items: end;
    }
    .visa_casing{
        display: inline-flex;
        gap: 14px;
        flex-direction: row-reverse;
        float: right;
        margin: unset;
        margin-bottom: 90px;
    }
    .visa_next{
        width: 121.49px;
        height: 39.37px;
    }
    .visa_progressBar{
        width: 700px;
        margin-right: auto;
        margin-left: auto;
    }
    .visa_percent{
        font-size: 10px;
    }
    #visa-Input{
        width: 469px;
    }
    #firstInput {
        width: 70px;
    }
    .visaCent {
        margin-top: 0;
    }
    .multiple{
        font-size: unset;
    }
    .visa_multiple{
        float: left;
        height: 23.2px;
        font-size: 9px;
    }
}
@media (min-width: 1024px){
    .visaLeft {
        width: 715px;
    }
    .visa_multiple{
        height: 40px;
        font-size: 16px;
    }
    .visaRight {
        width: 337px;
        padding-right: 20px;
    }
    .visaDivider {
        gap: 103px;
    }
    .visa_progress {
        height: 9px;
        margin-bottom: 5px;
    }
    .visa_percent {
        font-size: 15px;
        line-height: 20px;
    }
    .visa_progressBar {
        width: 77%;
        margin-left: 125px;
    }
    .visa_head {
        font-size: 48px;
    }
    .visa_information .heading {
        font-size: 20px;
        width: unset;
    }
    .ssl img{
        width: 209px;
    }
    #firstInput {
        width: 84px;
    }
    .visa_information .more-info {
        font-size: 20px;
    }
    #correct_{
        width: 663.33px;
        height: 58px;
        padding-right: 52px;
        padding-left: 52px;
        font-size: 14px;
        line-height: 21px;
    }
    .visa_inputField label {
        font-size: 14px;
    }
    #visa-Input {
        width: 667px;
    }
    #timiButton {
        width: 202px;
    }
    .visa_input{
        font-size: 14px;
        height: 41px;
        width: 315px;
    }
    #secondInput {
        width: 237px;
    }
    .visaFirstLast {
        gap: 30px;
    }
    .visa_selection .title {
        font-size: 14px;
    }
    .visa_timing {
        font-size: 12px;
        padding-right: 54px;
        width: unset;
    }
    .visa_moreInfo {
        font-size: 20px;
    }
    .visa_moreInfo span {
        font-size: 14px;
        line-height: 9px;
    }
    .visa_cc span {
        line-height: 21px;
        font-size: 14px;
    }
    .visa_next {
        width: 172px;
        height: 56px;
        font-size: 16px;
    }
    .visa_next img{
        width: 24px;
    }
}